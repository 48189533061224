<template>
  <div class="container">
    <div class="gmail">
      <div class="red-box"></div>
      <div class="right-border"></div>
      <div class="cream-box"></div>
      <div class="line right"></div>
      <div class="left-border"></div>
      <div class="gray-triangle"></div>
      <div class="fold left"></div>
      <div class="fold right"></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 100px;
}

.gmail {
  $red: #ff7765;
  $cream: #f6f6f6;
  $dark-red: #d06254;
  $gray: #979797;

  $width: 108px;
  $height: 78px;
  $strip-width: 12px;
  $inner-width: $width - 2 * $strip-width;
  $icon-border-radius: 8px;
  $cross-angle: 54deg;

  left: 50%;
  margin-left: -($width / 2);
  width: $width;
  height: $height;
  position: relative;

  div {
    position: absolute;
  }

  .red-box {
    background-color: $red;
    width: $width;
    height: $height;
    border-radius: $icon-border-radius;
  }

  .left-border {
    background-color: $red;
    left: 0px;
    top: $icon-border-radius;
    width: $strip-width;
    height: $height - $icon-border-radius;
    border-bottom-left-radius: $icon-border-radius;
  }

  .right-border {
    background-color: $dark-red;
    left: $width - $strip-width;
    top: $icon-border-radius;
    width: $strip-width;
    height: $height - $icon-border-radius;
    border-bottom-right-radius: $icon-border-radius;
  }

  .cream-box {
    background-color: $cream;
    left: $strip-width;
    width: $inner-width;
    height: $height;
  }

  .left {
    transform: rotate(360deg - $cross-angle);
    -o-transform: rotate(360deg - $cross-angle);
    -moz-transform: rotate(360deg - $cross-angle);
    -ms-transform: rotate(360deg - $cross-angle);
    -webkit-transform: rotate(360deg - $cross-angle);
  }

  .right {
    transform: rotate($cross-angle);
    -o-transform: rotate($cross-angle);
    -moz-transform: rotate($cross-angle);
    -ms-transform: rotate($cross-angle);
    -webkit-transform: rotate($cross-angle);
  }

  .fold {
    $fold-height: 67px;
    $fold-width: 14px;
    $fold-x-offset: 23px;

    background-color: $red;
    width: $fold-width;
    height: $fold-height;
    top: -11px;
    border-top-left-radius: $fold-width / 2;
    border-top-right-radius: $fold-width / 2;

    &.left {
      left: $fold-x-offset;
    }

    &.right {
      right: $fold-x-offset;
      border-top-right-radius: 3px;
    }
  }

  .line {
    background-color: $gray;
    height: 54px;
    width: 2px;
    top: 35px;
    right: 74px;
  }

  .gray-triangle {
    $triangle-height: 31px;

    border-top: transparent $triangle-height solid;
    border-bottom: transparent $triangle-height solid;
    border-right: $gray $inner-width / 2 solid;
    right: $strip-width;
    top: 16px;
  }
}
</style>
