<template>
  <div
    v-if="isShowing"
    ref="interactElement"
    :class="{
      isAnimating: isInteractAnimating,
      isCurrent: isCurrent
    }"
    class="card"
    :style="{ transform: transformString }">
    <AdCard
      :ad="card"
      :likeorpass.sync="likeorpass"
      @imageFailed="imageFailed" />
    <!-- TODO: Multiple flats in one card -->
  </div>
</template>

<script>
import interact from 'interactjs'
import AdCard from '@/components/AdCard.vue'

const ACCEPT_CARD = 'cardAccepted'
const REJECT_CARD = 'cardRejected'
const SKIP_CARD = 'cardSkipped'

export default {
  components: {
    AdCard
  },
  static: {
    interactMaxRotation: 5,
    interactOutOfSightXCoordinate: 600,
    interactOutOfSightYCoordinate: 600,
    interactYThreshold: 150,
    interactXThreshold: 100
  },

  props: {
    // ad: {
    //   type: Object,
    //   required: true
    // },
    card: {
      type: Object,
      required: true
    },
    isCurrent: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      isShowing: true,
      isInteractAnimating: true,
      isInteractDragged: null,
      interactPosition: {
        x: 0,
        y: 0,
        rotation: 0
      },
      cardPosition: {
        x: 0,
        y: 0
      },
      cardSize: {
        x: 0,
        y: 0
      }
    }
  },

  computed: {
    likeorpass () {
      const { x } = this.interactPosition
      return x / 5 / 100
    },
    grabByLower () {
      return this.cardSize.y / 2 < this.cardPosition.y
    },
    transformString () {
      if (!this.isInteractAnimating || this.isInteractDragged) {
        const { x, y, rotation } = this.interactPosition
        return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`
      }
      return null
    }
  },

  mounted () {
    const element = this.$refs.interactElement
    const _this = this

    interact(element).on('tap', function (event) {
      // FIX: Only let the top most card be clickable
      // _this.$router.push({ name: 'ad', params: { id: _this.card.id } })
      _this.$emit('click', _this.card.id)
      event.preventDefault()
    })

    interact(element).draggable({
      // FIX: Only let the top most card be draggable
      onstart: event => {
        this.isInteractAnimating = false
        this.cardPosition = {
          x: event.clientX - element.getBoundingClientRect().left,
          y: event.clientY - element.getBoundingClientRect().top
        }

        this.cardSize = {
          x: element.offsetWidth,
          y: element.offsetHeight
        }
      },

      onmove: event => {
        const { interactMaxRotation, interactXThreshold } = this.$options.static
        const x = this.interactPosition.x + event.dx
        const y = this.interactPosition.y + event.dy

        let rotation = interactMaxRotation * (x / interactXThreshold)
        if (this.grabByLower) {
          rotation = -rotation
        }
        this.interactSetPosition({ x, y, rotation })
      },

      onend: () => {
        const { x, y } = this.interactPosition
        const { interactXThreshold, interactYThreshold } = this.$options.static
        this.isInteractAnimating = true

        if (x > interactXThreshold) this.playCard(ACCEPT_CARD)
        else if (x < -interactXThreshold) this.playCard(REJECT_CARD)
        else if (y > interactYThreshold) this.playCard(SKIP_CARD)
        else this.resetCardPosition()
      }
    })
  },

  beforeDestroy () {
    interact(this.$refs.interactElement).unset()
  },

  methods: {
    imageFailed () {
      this.$emit('imageFailed')
    },
    likecard () {
      const { x, y } = { x: 150, y: 0 }
      const { interactXThreshold, interactYThreshold } = this.$options.static
      this.isInteractAnimating = true

      if (x > interactXThreshold) this.playCard(ACCEPT_CARD)
      else if (x < -interactXThreshold) this.playCard(REJECT_CARD)
      else if (y > interactYThreshold) this.playCard(SKIP_CARD)
      else this.resetCardPosition()
    },

    passcard () {
      const { x, y } = { x: -150, y: 0 }
      const { interactXThreshold, interactYThreshold } = this.$options.static
      this.isInteractAnimating = true

      if (x > interactXThreshold) this.playCard(ACCEPT_CARD)
      else if (x < -interactXThreshold) this.playCard(REJECT_CARD)
      else if (y > interactYThreshold) this.playCard(SKIP_CARD)
      else this.resetCardPosition()
    },

    hideCard () {
      setTimeout(() => {
        // console.log(this.card.id)
        this.$store.commit('REMOVE_PROSPECT', this.card.id)
        this.isShowing = false
        // this.$emit('hideCard', this.card)
      }, 300)
    },

    playCard (interaction) {
      const { interactOutOfSightXCoordinate,
        interactOutOfSightYCoordinate,
        interactMaxRotation } = this.$options.static

      let theRotation = 0
      let rot = this.interactPosition.rotation
      if (!rot || rot === 0) {
        theRotation = interactMaxRotation
      } else {
        theRotation = rot
      }

      this.interactUnsetElement()

      switch (interaction) {
        case ACCEPT_CARD:
          this.interactSetPosition({
            x: interactOutOfSightXCoordinate,
            rotation: theRotation
          })
          this.$emit(ACCEPT_CARD, this.card.id)
          break
        case REJECT_CARD:
          if (theRotation === 5) {
            theRotation = -theRotation
          }
          this.interactSetPosition({
            x: -interactOutOfSightXCoordinate,
            rotation: theRotation
          })

          this.$emit(REJECT_CARD)
          break
        case SKIP_CARD:
          this.interactSetPosition({
            y: interactOutOfSightYCoordinate
          })
          this.$emit(SKIP_CARD)
          break
      }

      this.hideCard()
    },

    interactSetPosition (coordinates) {
      const { x = 0, y = 0, rotation = 0 } = coordinates
      this.interactPosition = { x, y, rotation }
    },

    interactUnsetElement () {
      interact(this.$refs.interactElement).unset()
      this.isInteractDragged = true
    },

    resetCardPosition () {
      this.interactSetPosition({ x: 0, y: 0, rotation: 0 })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/index.scss';

$cardsTotal: 3;
// $cardsWidth: 640px;
// $cardsPositionOffset: 55vh * 0.06; // Original ofset (the bottom edge that sticks out)
$cardsPositionOffset: 20px;
// $cardsScaleOffset: 0.1; // Default scale size (how much smaller each card gets through out the stack)
$cardsScaleOffset: 0.05;
$defaultTranslation: $cardsPositionOffset * $cardsTotal;
$defaultScale: 1 - ($cardsScaleOffset * $cardsTotal);
$fs-card-title: 1.125em;

// #temp-card-push{opacity:0 !important;}

.card {
  @include card();
  @include absolute(0);
  // @include sizing(100% 80vw);
  // @include flex-center();

  @include after() {
    // @include sizing(21px 3px);
    // @include absolute(right 0 bottom 11px left 0);

    // margin: auto;
    // border-radius: 100px;
    // background: rgba($c-black, 0.3);
  }

  opacity: 0;
  transform: translateY($defaultTranslation) scale($defaultScale);
  transform-origin: 50%, 100%;

  will-change: transform, opacity;

  // height: 100vw;

  &.isCurrent {
    pointer-events: auto;
  }

  &.isAnimating {
    transition: transform 0.7s $ease-out-back;
  }
}

.cardTitle {
  margin: 0 0 15px;
  font-size: $fs-card-title;
}

@for $i from 1 through $cardsTotal {
  $index: $i - 1;
  $translation: $cardsPositionOffset * $index;
  $scale: 1 - ($cardsScaleOffset * $index);

  .card:nth-child(#{$i}) {
    z-index: $cardsTotal - $index;
    opacity: 1;
    transform: translateY($translation) scale($scale);

    @if $i == 3 {
      opacity: 0.5;
      -webkit-transition: opacity 0.4s ease-in-out;
      -moz-transition: opacity 0.4s ease-in-out;
      -ms-transition: opacity 0.4s ease-in-out;
      -o-transition: opacity 0.4s ease-in-out;
      transition: opacity 0.4s ease-in-out;
    } @else if $i == 2 {
    }

    @if $i != 1 {
      background-image: none;
      @include after() {
        @include sizing(0 0);
      }
    }
  }
}

#app-card-section .card-stack .card-item .card-image img{
  height: 50px;
}
</style>
