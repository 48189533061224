<template>
  <section id="app-card-section">
    <div class="ag-wrapper">
      <div class="ag-map">
        <LMap
          ref="admap"
          :options="mapOptions"
          :min-zoom="minZoom"
          :max-zoom="maxZoom"
          :zoom="14"
          style="height:100%">
          <LTileLayer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base" />

          <template v-if="currentProspects.length">
            <template v-for="(ad, index) in currentProspects">
              <LMarker
                v-if="index === 0"
                :key="ad.id"
                :lat-lng="{ lat: ad.lat, lng: ad.lng }"
                :icon="icon" />
            </template>
          </template>
        </LMap>
      </div>
      <div class="ag-filter">
        <div class="button"
             @click.prevent="toggleFilters">
          Filter
        </div>
      </div>
      <div class="ag-cardstack">
        <!--
<SwipeCardStack
                class="ag-card"
                ref="stack"
                :new="prospectsLoading"
                :cards.sync="currentProspects"
                :readytoshow.sync="picsReady"
                @cardAccepted="handleCardAccepted"
                @cardRejected="handleCardRejected"
                @cardSkipped="handleCardSkipped"
                @hideCard="removeCardFromDeck"
                @click="showAd" /> -->

        <div ref="agcard" class="ag-card" :style="{maxWidth:theSize.cardWidth + 'px', maxHeight: theSize.cardHeight + 'px'}" @click="getRefsData">
          {{ theSize }}
        </div>
      </div>
      <div class="ag-controls">
        c
      </div>
    </div>
  </section>
</template>

<script>
// Disables pinch zoom in safari
// document.addEventListener('gesturestart', function(e) {
//   e.preventDefault()
// })

import AdService from '@/services/AdService.js'
import KeyboardEvents from '@/components/KeyboardEvents.vue'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

import SwipeCardStack from '@/components/SwipeCardStack'
// import hello from '@/components/HelloI18n.vue'
import CardLikeButtons from '@/components/CardLikeButtons.vue'
// import AdCard from '@/components/AdCard.vue'
import Ad from '@/views/Ad.vue'
import MyFilters from '@/components/MyFilters.vue'

import { mapGetters } from 'vuex'
import L from 'leaflet'

import store from '@/store'

const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: false,
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  },
  {
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
  },
  {
    name: 'Wikimedia',
    visible: false,
    url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://foundation.wikimedia.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a>'
  },
  {
    name: 'Cartodb',
    visible: true,
    url:
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
  }
]

export default {
  beforeRouteEnter(to, from, next) {
    // console.log('Component based beforeRouteEnter')
    store.commit('SET_AD_DETAILS', false)
    next()
  },
  name: 'App',
  components: {
    SwipeCardStack,
    KeyboardEvents,
    LMap,
    LTileLayer,
    LMarker,
    CardLikeButtons,
    Ad,
    MyFilters
  },

  data() {
    return {
      theSize: { 'cardHeight': 450, 'cardWidth': 640 },
      // showFilters: false,
      toggleUpdate: true,
      // adDetails: false,
      picsReady: false,
      isReady: false,
      showMap: true,
      map: false,
      // prospects: [],
      center: { lat: 51.505, lng: -0.09 },
      opacity: 0.6,
      // token: 'your token if using mapbox',
      mapOptions: {
        zoomControl: true,
        attributionControl: true,
        zoomSnap: true,
        noBlockingAnimations: true
      },
      zoom: 13,
      minZoom: 1,
      maxZoom: 20,
      zoomPosition: 'topleft',
      attributionPosition: 'bottomright',
      layersPosition: 'topright',
      attributionPrefix: 'Vue2Leaflet',
      imperial: false,
      Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],

      icon: L.icon({
        iconUrl: '/img/marker.svg',
        iconSize: [35, 44],
        iconAnchor: [17.5, 44]
      }),
      tileProviders: tileProviders
    }
  },

  computed: {

    cardSize() {
      return this.theSize
    },

    ...mapGetters([
      'user',
      'listenToKeyboard',
      'prospects',
      'showFilters',
      'prospectsLoading',
      'prospectsPhotos',
      'adDetails',
      'memberships',
      'activeMemberships',
      'myAds',
      'myActiveAd'
    ]),
    // currentLocation() {
    //   return this.$store.getters.user
    // },
    // isReady() {
    //   return this.prospects.length > 0
    // },
    // listenToKeyboard() {
    //   return this.$store.getters.listenToKeyboard
    // },

    currentProspects() {
      let prospects = this.prospects
      prospects.map(p => (p.isShowing = true))
      return prospects
    },
    // showFilters() {
    //   return this.$store.state.showFilters
    // },
    // adList() {
    //   return this.prospects.map(p => {
    //     return p
    //   })
    // }

    fabianSize() {
      return this.$refs.agcard.parentElement.clientWidth
    }
  },
  watch: {
    adDetails() {
      if (this.adDetails == true) {
        this.$store.commit('SET_PROSPECT_MODAL', true)
      } else {
        this.$store.commit('SET_PROSPECT_MODAL', false)
      }
    },
    showFilters() {
      if (this.showFilters == true) {
        this.$store.commit('LISTEN_TO_KEYBOARD', false)
      } else {
        this.$store.commit('LISTEN_TO_KEYBOARD', true)
      }
    },
    prospects() {
      if (this.prospects.length > 0) {
        this.showMap = true
        // window.history.pushState(null, null, '/annons/' + this.prospects[0].id)
        this.moveMap()
        // this.showMap = false
        // this.moveMap()
      } else {
        this.$store.commit('LISTEN_TO_KEYBOARD', false)
        // window.history.pushState(null, null, '/annonser/')
        this.showMap = false
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getRefsData)
  },

  created() {
    window.addEventListener('resize', this.getRefsData)

    this.$store.commit('LISTEN_TO_KEYBOARD', true)

    // this.$nextTick(() => {
    //   // console.log('Next tick')
    //   this.map = this.$refs.admap.mapObject // work as expected
    //   // this.moveMap()
    // })
    // if (this.prospects.length < 1) {
    // this.loadMoreAds()
    // }
  },
  mounted() {
    this.$store.commit('SET_PROSPECTS_LOADING', true)

    this.$nextTick(() => {
      // console.log('Next tick')
      this.map = this.$refs.admap.mapObject // work as expected
      // this.map.forceRerender()
      // this.moveMap()
    })
    if (this.prospects.length < 1) {
      this.loadMoreAds()
    } else {
      this.$store
        .dispatch('preLoadImages', this.prospectsPhotos)
        .then(response => {
          this.$store.commit('SET_PROSPECTS_LOADING', false)
          // console.log(response)
        }).catch(err =>{
          Sentry.captureException(error);
        })

      this.moveMap()
      // window.history.replaceState(null, null, '/annons/' + this.prospects[0].id)
    }
    // this.loadMoreAds()

    // if (this.prospects.length) {
    //   this.$store.commit('LISTEN_TO_KEYBOARD', true)
    //   // this.moveMap()
    // }
  },

  methods: {

    getRefsData() {
      const { clientWidth, clientHeight } = this.$refs.agcard.parentElement
      // alert( this.$refs.agcard.parentElement.clientWidth)

      let cardHeight = clientHeight - 40

      if (cardHeight > 450) {
        cardHeight = 450
      }
      let cardWidth = cardHeight / 0.7

      if (cardWidth < clientWidth) {
        // console.log(cardWidth, clientWidth)
        this.theSize = { cardHeight, cardWidth }
      } else {
        cardWidth = clientWidth - 40
        if (cardWidth > 640) {
          cardWidth = 640
        }
        cardHeight = cardWidth / 1.42
        this.theSize = { cardHeight, cardWidth }
      }
    },
    closeModal() {
      this.$store.commit('SET_AD_DETAILS', false)
    },
    likeProspect(id) {
      this.$store.dispatch('likeProspect', id)
    },
    toggleFilters() {
      this.$store.commit('TOGGLE_FILTERS')
    },
    loadMoreAds() {
      this.$store.commit('SET_PROSPECTS_LOADING', true)
      this.$store.dispatch('updateProspects').then(() => {
        this.$store
          .dispatch('preLoadImages', this.prospectsPhotos)
          // .then(response => {
          //   // console.log(response)
          // })
          .catch(error =>{
            Sentry.captureException(error);
          })
      })
      // this.isReady = false
      // this.toggleUpdate = !this.toggleUpdate
      // this.$store.commit('SET_PROSPECTS_LOADING', true)
      // this.$store.dispatch('updateProspects').then(() => {
      //   this.$store.dispatch('preLoadImages', this.prospectsPhotos).then(() => {
      //     this.$store.commit('SET_PROSPECTS_LOADING', false)
      //     this.$store.commit('LISTEN_TO_KEYBOARD', true)
      //   })
      //   // this.$store.dispatch('preLoadImages', this.prospectsPhotos)
      // })
    },
    showAd(id) {
      // this.adDetails = id
      this.$store.commit('SET_AD_DETAILS', id)
    },
    moveMap() {
      this.$refs.admap.setCenter({
        lat: this.$store.getters.currentAd.lat,
        lng: this.$store.getters.currentAd.lng
      })
    },
    keyboardEvent(e) {
      if (e.code === 'ArrowLeft') {
        this.$store.commit('SET_AD_DETAILS', false)
        // this.adDetails = false
        this.passcard()
      } else if (e.code === 'ArrowRight') {
        this.$store.commit('SET_AD_DETAILS', false)

        // this.adDetails = false
        this.likecard()
      } else if (e.code === 'Space') {
        if (this.adDetails) {
          // this.adDetails = false
          this.$store.commit('SET_AD_DETAILS', false)
        } else {
          this.showAd(this.prospects[0].id) // this.$router.push({ name: 'ad', params: { id: this.card.id } })
        }
      }
    },
    likecard() {
      this.$store.commit('SET_AD_DETAILS', false)
      // console.log('The like')
      this.$refs.stack.likecard()
    },
    passcard() {
      // console.log('DO THE DODOD')
      this.$store.commit('SET_AD_DETAILS', false)
      // console.log('The pass')
      this.$refs.stack.passcard()
    },
    visitAd() {
      this.$refs.stack.visitAd()
    },

    youNeedAnAd() {
      this.$swal
        .fire({
          title: 'Du har ingen annons!',
          text:
            'För att kunna anmäla intresse eller ointresse på bostäder på BytBostad behöver du ha en annons!',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#64BCB2',
          // cancelButtonColor: '#61bbb2',
          confirmButtonText: 'Skapa annons!',
          cancelButtonText: 'Avbryt'
        })
        .then(result => {
          if (result.value) {
            // console.log(result.value)
            this.$router.push({ name: 'create-ad' })
          }
        }).catch(error =>{
          Sentry.captureException(error);
        })
    },
    youNeedMembership() {
      this.$swal
        .fire({
          title: 'Intresseanmälan sparades inte!',
          text:
            'För att kunna anmäla intresse på bostäder på BytBostad behöver du ha ett aktivt medlemskap!',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#64BCB2',
          // cancelButtonColor: '#61bbb2',
          confirmButtonText: 'Aktivera ditt konto',
          // cancelButtonText: 'Avbryt'
        })
        .then(result => {
          if (result.value) {
            // console.log(result.value)
            this.$router.push({ name: 'checkout' })
          }
        }).catch(error =>{
          Sentry.captureException(error);
        })
    },
    handleCardAccepted(id) {
      // console.log('handleCardAccepted')
      // alert(this.activeMemberships.length)
      if (this.myAds.length === 1) {
        // console.log('Gilla: ' + id)
        // console.log('Från: ' + this.myActiveAd)
        // console.log(this.myActiveAd + ' gillar '+ id)
        AdService.likeAd(this.myActiveAd, id, 1).then(res => {
          // console.log(res.data)
          if (res.data.data == 'NO_MEMBERSHIP') {
            // alert('Saknar medlemskap')
            this.youNeedMembership()
          }
        }).catch(error => {
          Sentry.captureException(error);
        })
      } else {
        // alert('Du har ingen annons, kan inte gilla: ' + id)
        this.youNeedAnAd()
      }
      // AdService.likeProspect
    },
    handleCardRejected(id) {
      // console.log('handleCardRejected')
      // console.log('Ogilla: ' + id)
      if (this.myAds.length === 1) {
        AdService.passAd(this.myActiveAd, id, 0)
        // .then(res => {
        //   console.log(res.data)
        // })
        .catch(error => {
          Sentry.captureException(error);
          // console.log(err)
        })
      } else {
        // alert('Du har ingen annons, kan inte gilla: ' + id)
        this.youNeedAnAd()
      }
    },
    handleCardSkipped() {
      // console.log('handleCardSkipped')
    },
    removeCardFromDeck() {
      this.$store.commit('REMOVE_PROSPECT')
      // this.prospects.shift()
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://cdn.jsdelivr.net/npm/animate.css@3.5.1');

.ag-wrapper{

//     align-items: center;
//    justify-items: center;

        height:calc(100vh - 74px);
        display:grid;
        grid-template-columns: 1fr 2fr;
        grid-template-areas:
            "map filter"
            "map cardstack"
            "map controls";
            grid-template-rows: 70px 1fr 120px;
    }

@media(max-width:800px){
    .ag-map{
        display:none;
    }
    .ag-wrapper{
        grid-template-columns: 1fr;

        grid-template-areas:
            "filter"
            "cardstack"
            "controls";
    }
}

    .ag-filter {
        display:grid;
        grid-area: filter;
        background: yellow;
        height: 70px;
        place-items: center;
        align-content:center;
    }

    .ag-map {
        grid-area: map;
        background: green;
    }

    .ag-cardstack{
        display:grid;
        grid-area: cardstack;
        background: red;
        place-items: center;
        // grid-template-columns: 1fr 8fr 1fr;
        // grid-template-rows: 1fr 8fr 1fr;

    }

    .ag-controls {
        grid-area: controls;
        background: turquoise;
        height:120px;
    }

    .ag-card{
        background-color: orange;
        // grid-column: 1/-1;
        // grid-row-start: 1/-1;
        display:block;
        width:100%;
        height:100%;
        /* width:minmax(20px, 500px);
        height:minmax(20px, 500px); */
    }

.adModal {
  animation-duration: 0.5s;
  // animation-delay: 2s;
  // animation-iteration-count: infinite;
}

.sidemap {
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
// TODO: Style the adModal

// .adModal {
//   background-color: red;
//   width: 100%;
//   height: 100%;
// }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
