<template>
  <div class="preloader-wrapper">
    <div class="preloader-inner">
      <img src="/img/loader.svg" alt="Laddar">
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/scss/abstracts/_variables.scss';

.preloader-wrapper {
  background-color: $app-color-background;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 490;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .preloader-inner {
    margin: 0px auto;
  }
}

// Auto-generated transition names from SwipeCardStack.vue
.preloader-enter-active,
.preloader-leave-active {
  transition: opacity 0.5s;
}
.preloader-enter,
.preloader-leave-to {
  opacity: 0;
}
</style>
