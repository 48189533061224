<template>
  <main>
    <div class="jumbotron">
      <div class="tint">
        <div class="jumbo-content">
          <h1>Vill du bo {{ currentword }}</h1>
          <h2>På BytBostad hittar du bytet!</h2>
          <!-- <button class="button-submit secondary">Börja byta idag</button> -->
          <router-link
            v-if="myAds.length"
            tag="button"
            :to="{ name: 'adlist' }"
            class="button-submit secondary">
            Hitta byten
          </router-link>

          <router-link
            v-else
            tag="button"
            :to="{ name: 'create-ad' }"
            class="button-submit secondary">
            Börja byta idag
          </router-link>
        </div>
      </div>
    </div>

    <!--
    <div class="grid-container">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 ">
          <p class="ingress">
            Du har rätt att byta din bostad om du har behov av en annan. Kanske
            vill du bo närmare jobbet eller barnen? Har barnen flyttat ut och du
            bor för stort, eller skall du flytta ihop med någon och behöver bo
            större?
          </p>

          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-7">
              <p>
                Oavsett vilken anleding du har för att vilja göra ett
                <strong>lägenhetsbyte</strong> så är det en tidsödande process
                att hitta ett byte som matchar just det som passar dig.
              </p>
              <p>
                Därför har vi utvecklat <strong>BytBostad</strong>. Vi matchar
                dig med tusentals andra bostadsinnehavare och ser till att hitta
                ett direktbyte eller triangelbyte.
              </p>
              <p>
                Du behöver bara berätta vad du har för bostad idag samt vad du
                söker, sedan säger du <span class="primary">ja</span> eller
                <span class="secondary">nej</span> till de bostäder som
                presenteras.
              </p>
              <p>
                Våra robotar kopplar ihop dig med andra bostadsinnehavare helt
                automatiskt!
              </p>
            </div>
            <div class="cell small-12 medium-5">
              <img src="/img/ai.jpg" alt="Robot" />
            </div>
          </div>
          <div class="grid-x grid-padding-x usp">
            <div class="cell small-12 medium-4">
              <h1>Snabbt</h1>
              <p>
                Det enda du behöver göra är att berätta vilka bostäder du
                gillar, vi matchar sedan ihop både direktbyten och
                triangelbyten.
              </p>
            </div>
            <div class="cell small-12 medium-4">
              <h1>Enkelt</h1>
              <p>
                Enligt lagen har du rätt att byta bostad om det finns behov. Det
                kan vara att du t.ex. behöver ha lägre hyra, bo närmare jobbet
                eller att du har behov av att bo större.
              </p>
            </div>

            <div class="cell small-12 medium-4">
              <h1>Coolt</h1>
              <p>
                Enligt lagen har du rätt att byta bostad om det finns behov. Det
                kan vara att du t.ex. behöver ha lägre hyra, bo närmare jobbet
                eller att du har behov av att bo större.
              </p>
            </div>
          </div>

          <div class="grid-x grid-padding-x ">
            <div class="cell small-12 medium-5">
              <img src="/img/smile.jpg" />
            </div>
            <div class="cell small-12 medium-7">
              <h2>Man kan alltid bo bättre</h2>
              <p>
                Även om man tror sig ha hittat sin drömbostad så finns det
                alltid en möjlighet att byta upp sig. Det kan handla om en
                bostad i samma trappuppgång men med lägre hyra, bättre utsikt
                eller en Terrass.
              </p>
              <p>
                Med hjälp av triangelbyten kan vi hitta byten du inte trodde var
                möjliga.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-x grid-padding-x ">
      <div class="cell small-12 "><h1>&nbsp;</h1></div>
    </div>

    <div class="grid-x grid-padding-x ">
      <div class="cell small-12 medium-6  box1">
        <div class="grid-x ">
          <div class="cell small-12 large-7 large-offset-4">
            <h1>Fokus på aktivitet</h1>
            <p>
              Vi jobbar kontinuerligt med att hålla våra medlemmar uppdaterade
              och aktiva. Ingen vill sitta och vänta på ett byte.
            </p>
          </div>
        </div>
      </div>
      <div class="cell small-12 medium-6 box2">
        <div class="grid-x ">
          <div class="cell large-1"></div>
          <div class="cell small-12 large-7">
            <h1>Störst i storstäderna</h1>
            <p>
              De flesta av våra medlemmar söker bostad i Stockholm eller
              Göteborg, men det finns önskemål om bostäder i nästan hela
              Sverige.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-x grid-padding-x ">
      <div class="cell small-12 "><h1>&nbsp;</h1></div>
    </div>

    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8 medium-offset-2">
        <h1>De flesta vill bo lite större</h1>
        <p class="ingress">
          Den vanligaste anledningen att byta bostad är att man vill bo lite
          större än man gör idag, hela 44% av våra medlemmar har angett att dom
          vill göra ett <strong>lägenhetsbyte</strong> på grund av att dom bor
          för litet. 15% av våra medlemmar har däremot uppgett att dom bor för
          stort.
        </p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-4 medium-offset-2 ">
        <h2>Göteborg ligger tvåa på önskelistan</h2>
        <p>
          Göteborg är Sveriges andra störta stad. Det är också den näst
          populäste staden att flytta till. 20% av våra medlemmar vill flytta
          till <a href="/goteborg/">Göteborg</a> medans hela 63% vil flytta till
          eller inom Stockholm.
        </p>
        <h2>Stockholm är populärast</h2>
        <p>
          Stockholm är vår huvudstad och också den stad dit flest vill flytta.
          Dom populäraste områderna är Kungsholmen, Vasastaden, Östermalm och
          Södermalm.
        </p>

        <h2>Hyresrätt är vanligast</h2>
        <p>
          Även om man får byta sin bostadsrätt så bor dom flesta av våra
          medlemmar just nu i en hyresrätt. 97,8% bor i hyresrätt.
        </p>
      </div>
      <div
        class="cell small-12 medium-6"
        style="background:url('/img/goteborg/edvin-johansson-1272415-unsplash.jpg');background-size:cover;  background-position: center center;"
      >
      </div>
    </div>
    <div class="grid-x grid-padding-x footerinfo">
      <div class="cell small-12 ">
        <center>
          <p>
            <strong>BytBostad</strong> drivs av Svenska Bostadssajterna AB
            (559160-8657) Telefon: 08-121314 Epost: hej@bytbostad.se Följs oss
            på <a>Facebook</a>, <a>Instagram</a> eller <a>Twitter</a>
          </p>
        </center>
      </div>
    </div>
    -->
  </main>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      words: [
        'billigare',
        'större',
        'centralare',
        'mindre',
        'närmare jobbet',
        'ensam',
        'bättre'
      ],
      currentnumber: 0
    }
  },
  computed: {
    ...mapGetters(['myAds']),
    currentword () {
      return this.words[this.currentnumber]
    }
  },
  mounted () {
    this.intervalid1 = setInterval(() => {
      // this.changes = (Math.random() * 100).toFixed(2) + '%'
      // console.log(this.changes)
      this.changeWord()
    }, 1500)
  },
  methods: {
    changeWord () {
      if (this.currentnumber + 1 == this.words.length) {
        this.currentnumber = 0
      } else {
        this.currentnumber++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/abstracts/_variables.scss';

// .box1 {
//   background-color: $color-primary;
// }

// .box2 {
//   background-color: $color-secondary;
// }

// .box3 {
//   background-color: #ffba52;
// }

// .box1 h1,
// .box2 h1,
// .box1 p,
// .box2 p {
//   color: white;
// }
// .primary,
// .secondary {
//   font-weight: bold;
//   text-decoration: underline;
// }
// .primary {
//   color: $color-primary;
// }

// .secondary {
//   color: $color-secondary;
// }
// .grid-container {
//   max-width: 800px;
//   // margin-top: 74px;
// }

// .usp h1 {
//   // text-align: center;
//   color: $color-primary;
// }

// .usp h1:before {
//   content: '✔';
//   color: $color-secondary;
//   margin-right: 6px;
// }

// .footerimage {
//   // margin-top: 74px;
//   height: 500px;
//   width: 100%;
//   // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
//   //   url('/img/familj.jpeg');

//   background-image: linear-gradient(
//       rgba($color-secondary, 0.5),
//       rgba($color-primary, 0.5)
//     ),
//     // url('/img/stockholm.jpg');
//       url('/img/goteborg/jonas-jacobsson-1316804-unsplash.jpg');

//   background-size: cover;
//   background-position: center center;
//   background-attachment: fixed;

//   display: table-cell;
//   // width: 500px;
//   // height: 500px;
//   vertical-align: middle;
//   text-align: center;
// }

// .jumboimage {
//   margin-top: 74px;
//   height: 60vh;
//   width: 100%;
//   // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
//   //   url('/img/familj.jpeg');

//   background-image: linear-gradient(
//       rgba($color-secondary, 0.5),
//       rgba($color-primary, 0.5)
//     ),
//     url('/img/familj.jpeg');

//   background-size: cover;
//   background-position: center center;

//   display: table-cell;
//   // width: 500px;
//   // height: 500px;
//   vertical-align: middle;
//   text-align: center;
// }

// .jumboimage h1,
// .footerimage h1 {
//   // display: table;
//   // margin: 0 auto;
//   display: inline-block;
//   width: 100vw;
//   // height: 200px;
//   color: $color-primary;
//   color: white;
//   font-size: 70px;
//   line-height: 64px;
//   margin-bottom: 20px;
// }
// .footerimage h2 {
//   color: white;
// }
// .jumboimage h2 {
//   display: inline-block;
//   width: 100vw;
//   // height: 200px;
//   color: $color-primary;
//   color: white;
//   font-size: 30px;
//   // line-height: 64px;
//   margin-top: -15px;
// }
// .ingress {
//   font-size: 24px;
//   line-height: 30px;
// }

// .footerinfo {
//   background-color: white;
// }
// .footerinfo p {
//   color: #979797;
//   font-size: 12px;
// }
</style>
