<template>
<div>


<iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://support.bytbostad.se/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Kontakta+kundtj%C3%A4nst&submitTitle=Skicka&submitThanks=Tack!+Vi+%C3%A5terkommer+inom+kort!&searchArea=no&helpdesk_ticket_email=fabian" scrolling="no" height="410px" width="100%" frameborder="0" >
</iframe>
</div>
</iframe>

</template>


<script>
export default {
  components: {},
  data() {
    return {
     url: 'https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js'
    }
  },
}


</script>
<style type="text/css" media="screen, projection">

	@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css); 
</style> 
