<template>
  <SinglePage>
    <div class="grid-x align-center">
      <div class="cell small-12 medium-7">
        <h1>Om oss</h1>
        <p>BytBostad är en tjänst som hjälper dig att hitta och genomföra lägenhetsbyten. Sajten har funnits sedan <strong>2009</strong> och vi har hjälpt tusentals svenskar att byta sin bostad.</p>
        <p>För att kunna använda tjänsten behöver du ha en bostad (förstahandskontrakt eller bostadsrätt).</p>
        <p>Det är gratis att lägga upp sin annons men för att kunna anmäla intresse för bostäder måste man ha ett aktivt medlemskap.</p>
        <h3>Företagsinfo</h3>
        <p>Om du vill skicka brev till oss gör du det på följande adress:</p>
        <pre>Svenska Bostadssajterna AB
Kivra: 559160-8657
106 31 Stockholm</pre>
      </div>
    </div>
  </SinglePage>
</template>

<script>
import SinglePage from '@/components/SinglePage.vue'
export default {
  components: {
    SinglePage
  }
}
</script>
