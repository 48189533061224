<template>
  <div class="centermiddle">
    <h1>Kunde inte hittas</h1>
    <h3>Sidan kunde inte hittas</h3>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.centermiddle{
    display: flex;           /* establish flex container */
    flex-direction: column;  /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center;     /* center items horizontally, in this case */
    height: 100vh;
}
</style>
