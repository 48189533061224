<template>
  <div>
    <div
      class="avatar-wrap"
      :style="{ width: size + 'px', height: size + 'px' }">
      <!-- Show either span or avatar depending on if the avatar exists -->
      <span
        v-if="!user.profile_picture"
        :style="{ fontSize: fontSize + 'px', lineHeight: size + 'px' }">{{ shortname }}</span>
      <img v-else :src="user.profile_picture" :alt="user.name">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: [Object, Boolean],
      default () {
        return {}
      }
    },
    size: {
      type: Number,
      default: 44
    }
  },
  computed: {
    fontSize () {
      return this.size * 0.4318181818
    },
    shortname () {
      if (this.user.firstname) {
        return this.user.firstname.charAt(0).toUpperCase() + this.user.lastname.charAt(0).toUpperCase()
      } else {
        return '?'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/abstracts/_variables.scss';
@import '@/scss/abstracts/_mixins.scss';

.avatar-wrap {
  //   width: 44px;
  //   height: 44px;
  overflow: hidden;
  background-color: $color-primary;
  @include br(50%);
  span {
    font-family: 'Nunito', sans-serif;
    // font-size: 19px;
    font-weight: 700;
    // line-height: 44px;
    width: 100%;
    color: #ffffff;
    text-align: center;
    display: block;
  }
  img {
    display: block;
    width: 100%;
  }
}
</style>
