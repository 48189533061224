<template>
  <SinglePage>
    <div class="grid-x align-center">
      <div class="cell small-12 medium-7 terms">
        <article>
          <h1>Användarvillkor &amp; Integritetspolicy</h1>
          <p>Dessa användarvillkor gäller från och med 2019-06-21</p>

          <h2>Tjänsten</h2>
          <p>När du registrerar dig på <strong>BytBostad</strong> kan du skapa en profil och lägga ut en annons för din bostad. Syften med tjänsten är att sammanföra människor som vill byta bostad med varandra.</p>

          <h2>Garanti</h2>
          <p><strong>BytBostad</strong> kan inte lämna någon garanti för att du kommer att hitta ett passande byte, eller att hyresvärdarna kommer att godkänna ett eventuellt byte som du hittat via tjänsten.</p>

          <h2>Innehåll</h2>
          <p>Du ansvarar själv för den information du publicerar på sajten, och att informationen du lämnar är sann. All information i beskrivningen av din bostad måste stämma överens med ditt hyreskontrakt.</p>
          <p>Det är inte tillåtet att använda BytBostad för att marknadsföra andra tjänster. Det är enligt lag ej tillåtet att begära pengar för byten av hyresrätter.</p>
          <p>BytBostad förbehåller sig rätten att neka annonser som inte har komplett information, eller om innehållet kan anses olämpligt eller stötande. BytBostad har rätt att redigera eller komplettera annonser som inte är kompletta genom att söka information från andra källor t.ex. myndigheter, register och kartleverantörer.</p>
          <p>BytBostad har rätt att återpublicera din annons med bilder i andra medier och på andra sajter för att maximera chansen för dig att hitta ett passande byte. BytBostad har rätt att använda de bilder du laddar upp, men du äger fortfarande rättigheterna till bilden och BytBostad har inte rätt att sälja bilderna vidare.</p>
          <p>Innehållet på BytBostad är publikt och kan ses av både besökare och sökmotorer.</p>
          <h3>Uppgifter som är publika</h3>
          <ul>
            <li>Bostadens adress</li>
            <li>Information om bostaden som t.ex. hyra, storlek mm.</li>
            <li>Beskrivning av bostaden</li>
            <li>Bilder på bostaden</li>
          </ul>
          <p>När du markerar intresse för andra bostäder på BytBostad deltar du i <em>byteskedjor</em>. Om alla medlemmar i en och samma byteskedja har <strong>tackat ja</strong> till varandras bostäder uppstår en <strong>machning</strong>, ett möjligt byte. I samband med detta får alla medlemmar i byteskedjan tillgång till kontaktuppgifterna till övriga medlemmar i byteskedjan för att ni skall kunna genomföra ett byte. Detta innebär att dina kontaktuppgifter delas med andra medlemmar på sajten (men bara i de kedjor där du tackat ja).</p>
          <h3>Uppgifter som delas till medlemmarna i en byteskedja vid en matchning</h3>
          <ul>
            <li>Ditt namn</li>
            <li>Ditt telefonnummer</li>
            <li>Din epostadress</li>
          </ul>
          <p>Du kan när som helst välja att ta bort din intresseanmälan och på så sätt hindra att dina personuppgifter delas med de övriga medlemmarna i byteskedjan.</p>
          <p>Du kan när som helst radera ditt medlemskap på BytBostad. Vi raderar då alla uppgifter vi har om dig ur vår databas samt raderar bilder och beksrivning av din bostad samt avpublicerar den från hemsidan. Vi behåller information såsom storlek, hyra mm. för att kunna förbättra vår tjänst.</p>

          <h2>Personuppgiftshantering</h2>
          <p><strong>Svenska Bostadssajterna AB</strong> är personuppgiftsansvarig för behandligen av dina personuppgifter.</p>
          <p>Svenska Bostadssajterna AB's tillgång till dina personuppgifter som nämnts i detta avtal är en förutsättning för att vi skall kunna tillhandahålla våra tjänster.</p>
          <p>Vi samlar in två typer av information:</p>
          <h3>Uppgifter du själv lämnar till oss</h3>
          <p>Vi sparar de uppgifter du själv ger till oss, eller som du delar med dig till oss av genom tredjeparts-tjänster som t.ex. Facebook-inloggning.</p>
          <p>Vi lagrar ditt namn för att kunna presentera det för övriga medlemmar i en byteskedja när den får 100% matchning.</p>
          <p>Vi lagrar din epostadress för att kunna ha kontakt med dig samt låta övriga medlemmar i en byteskedja kunna kontakta dig. Vi använder även epostadressen för att kommunicera med dig, om du t.ex. är med i byteskedjor där du är den enda som inte tagit ställning ännu.</p>
          <p>Om du inte loggat in på en längre tid kan vi även komma att maila dig för att höra om du fortfarande är intresserad av att ha din annons kvar.</p>
          <p>Vi lagrar adressen till de bostäder du lägger upp på sajten för att kunna presentera dom som bytesförlsag för våra övriga medlemmar.</p>
          <p>Uppgifter i denna kategorin är alltså saker som du själv fyllt i på sajten.</p>
          <h3>Uppgifter som samlas in när du använder tjänsten</h3>
          <p>Förutom det du själv fyller i så samlar vi även in uppgifter automatiskt, t.ex. din IP-adress, vilken typ av webbläsare du använder, vilken skärmupplösning du har mm. Vi samlar även in information om vilka bostadsannonser du besöker samt om du markerar dessa som intressanta eller ointressanta.</p>
          <h2>Vad används uppgifterna till?</h2>
          <p>Alla uppgifter vi samlas in används för att kunna erbjuda dig en tjänst för bostadsbyte.</p>

          <h2>Bolagsinformation</h2>
          <p>Företaget som diver BytBostad är:</p>
          <pre>Svenska Bostadssajterna AB
Kivra: 559160-8657
106 31 Stockholm
</pre>
          <p>Eventuella frågor skickas till info@bytbostad.se</p>
        </article>
      </div>
    </div>
  </SinglePage>
</template>

<script>
import SinglePage from '@/components/SinglePage.vue'
export default {
  components: {
    SinglePage,

  }
}
</script>

<style scoped>

article {
counter-reset : h2;
	}

h2 {
counter-reset : h3;
	}

h3 {
counter-reset : h4;
	}

h4 {
counter-reset : h5;
	}

h5 {
counter-reset : h6;
	}

article h2:before {
content : counter(h2,decimal) ". ";
counter-increment : h2;
	}

article h3:before {
content : counter(h2,decimal) "." counter(h3,decimal) ". ";
counter-increment : h3;
	}

article h4:before {
content : counter(h2,decimal) "." counter(h3,decimal) "." counter(h4,decimal) ". ";
counter-increment : h4;
	}

article h5:before {
content : counter(h2,decimal) "." counter(h3,decimal) "." counter(h4,decimal) "." counter(h5,decimal) ". ";
counter-increment : h5;
	}

article h6:before {
content : counter(h2,decimal) "." counter(h3,decimal) "." counter(h4,decimal) "." counter(h5,decimal) "." counter(h6,decimal) ". ";
counter-increment : h6;
	}

h2.nocount:before, h3.nocount:before, h4.nocount:before, h5.nocount:before, h6.nocount:before {
content : "";
counter-increment : none;
	}

</style>
