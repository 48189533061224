<template>
  <div style="display:grid;max-width: 400px;place-items: stretch;gap:1rem;">
    <!-- <template v-if="fbConnected && fbSdkReady ">
      <button class="button-submit facebook" @click.prevent="loginWithFB">
        !Fortsätt med Facebook
      </button>
    </template> -->

    <template v-if="fbSdkReady && !fbConnected">
      <!-- Facebook SDK is ready but the user is not logged in to our app -->
      <!-- <button v-if="!fbRefused" class="button-submit secondary facebook" @click.prevent="loginWithFB">
        !!Fortsätt med Facebook
      </button> -->
        <button v-if="!registerbyemail" class="button-submit secondary facebook" @click.prevent="loginWithFB">
          Använd Facebook
        </button>


        <h3 v-show="!registerbyemail" style="text-align: center;">
          Eller
        </h3>
        <!-- <h3>Registrera dig med epost</h3> -->
      <form v-if="!formError" @submit.prevent="submitForm">
        <FloatLabel v-show="registerbyemail">
          <input v-model="username" type="email" name="email" placeholder="Ange din epost" required autocomplete="email" v-on:keyup.enter="submitForm" @submit.prevent="submitForm">
        </FloatLabel>
        <button v-if="!registerbyemail" class="button-submit secondary" @click.prevent="registerbyemail = !registerbyemail">
          Använd epost
        </button>
        <button v-show="registerbyemail" class="button-submit secondary">
          Fortsätt
        </button>
      </form>
      <div v-if="formError">
        <div style="display:grid;align-items:center;justify-items:center;">
          <EmailAnimation />
          <h3 style="margin-top:20px;">
            Kolla din mail
          </h3>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import FloatLabel from '@/components/FloatLabels.vue'
import { mapGetters } from 'vuex'
import store from '@/store'
import EmailAnimation from '@/components/EmailAnimation.vue'

import { facebookSDK } from '@/mixins/facebook-sdk.js'

export default {
  components: {
    FloatLabel,
    EmailAnimation
  },
  mixins: [facebookSDK],
  data() {
    return {
      formError: false,
      username: '',
      referer: 'FBButton',
      registerbyemail: true,
    }
  },
  computed: {
  // ...mapGetters(['fbConnected', 'fbSdkReady', 'fbRefused', 'fbFirstName'])
    ...mapGetters(['fbConnected', 'fbSdkReady', 'fbFirstName', 'fbRefused'])
  },
  methods: {

    submitForm() {

      


      console.log('Submitting from FBButton', this.username)
      if(!this.username){
        alert('Du måste ange en epostadress')
        return false
      }
      this.$store.dispatch('registerEmail', this.username).then(res => {
        // console.log('Submit form dispatch')
        // console.log(res)
        // this.submitted = true
        // this.$router.push({name: 'my-account'})
      }).catch(err => {
        this.formError = true
        Sentry.captureException(err);
        // console.log(err)
        // this.$store.dispatch('forgotPW', this.username)
        this.$store.dispatch('resetpw', this.username).then(response => {
        // console.log(response)
        // this.submitted = true
        }).catch(error =>{
          Sentry.captureException(error);
        })
      })
    },
    logoutWithFB() {
    // window.FB.logout(function (response) {
      FB.logout(function(response) {
        // document.location.reload();
        // _this.fbConnected = false;
        // console.log('Loggade ut med FB från FBButton')
        store.commit('SET_FB_CONNETED', false)
        // console.log(response)
      // });
      })
    },

    // Function to log in with facebook
    loginWithFB() {
    // facebookSDK.login();
      // Fetch the FB object from the browser
      // TODO: Need to find a way to access FB as a global object rather than through window, as i want to be able to run this as a server side render.
      window.FB.login(function(response) {
        // If we are connected...
        if (response.status === 'connected') {
          // We are connected to facebook
          store.commit('SET_FB_CONNETED', true)
          // Take the FB-token and run it through our login function.
          store.dispatch('loginFB', response.authResponse.accessToken)
        } else {
          // Otherwise we were refused
          store.commit('SET_FB_REFUSED', true)
          // console.log('INTE ANSLUTEN')
        }
        // For now we will only ask for the email, name and profile are given by default
        // TODO: handle if someone doesn't give access to email, not sure how exactly
      }, { scope: 'email' })
    }
  },

}

// Function to get a cookie by name
function getCookie(name) {
    const nameEQ = name + "=";
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
        let cookie = cookiesArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) === 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
}
</script>

<style scoped>
button, input{
  /* width:100%; */

}
.facebook{
    background: url(/img/fb-white.svg);
    background-color: #3C5A99;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position-y: 50%;
    background-position-x: 25px;
    padding-left:70px;

  background-color: #3C5A99;
}
.facebook:hover{
  background-color: rgb(94, 120, 177);
  /* border: 2px #8b9dc3; */
}

</style>
