<template>
  <main>
    <div class="grid-container">
      <div class="grid-x align-center">
        <div class="cell small-12">
          <h1>Mina annonser</h1>

          <MyAdList :ads="myAds" />

          <!-- <pre>{{myAds}}</pre> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import MyAdList from '@/components/MyAdList.vue'

export default {

  components: {
    MyAdList
  },
  computed: {
    ...mapGetters(['myAds', 'user']),

  }

}
</script>

<style scoped>
main{
    margin-top:74px;
}
</style>
