import store from '@/store'

export let facebookSDK = {
  mounted () {
    let _this = this
    this.$nextTick(() => {
      window.fbAsyncInit = function () {

        window.FB.init({
          appId: '162588207446399',
          xfbml: true,
          version: 'v3.2'
        })
        window.FB.AppEvents.logPageView()
        _this.FB = FB
        // console.log('FB SDK was initialized as mixin')
        store.commit('SET_FB_SDK', true)
      };
      (function (d, s, id) {
        let js; let fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) { return }
        js = d.createElement(s); js.id = id
        js.src = '//connect.facebook.net/sv_SE/sdk.js#xfbml=0&appId=162588207446399&version=v3.2'
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    })
  },
  data () {
    return {
      FB: {}
    }
  }
}
