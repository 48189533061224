<template>
  <!-- <main id="page-wrap" v-if="ad.photos" class="bostad-wrap" :style="modalStyle"> -->
  <main id="page-wrap" :key="routeKey" class="bostad-wrap" :class="modalStyle">
    <div v-if="adLoaded">
      <map-view :ad="ad" :current-flat="showFlat" @toggleflat="toggleflat" />
      <div v-if="opener == 'modal'" class="grid-x grid-margin-x closebar" style="margin-bottom:10px;margin-top:-10px;padding:10px 0;argin-left: 0;margin-right: 0;padding-left: 0;padding-right: 0">
        <div class="cell small-12 ">
          <div class="grid-container">
            <div class="grid-x grid-margin-x">
              <div v-if="!thisMatch" class="cell small-12 share-url show-for-small-only">
                <span v-clipboard:copy="shareURL" v-tooltip.click="{content:'Kopierad! 📋', delay: 1000}"
                      @focus="$event.target.select()">{{ shareURL }}</span>
              </div>

              <div class="cell medium-8 show-for-medium">
                <button class="button facebook" @click.prevent="FBShare">
                  Dela på facebook
                </button>

                <!-- {{shareURL}} -->

                <!-- <div class="container">
    <input type="text" v-model="shareURL">
    <button type="button" class="button"
      v-clipboard:copy="shareURL"
      v-tooltip.click="{content:'Länken är kopierad!', delay: 3000}"
      >Kopiera länken</button>
  </div> -->
              </div>
              <div class="cell small-12 medium-4">
                <div style="float:right;">
                  <button class="button" @click.prevent="$emit('closeModal')">
                    Stäng
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="grid-container" :class="{'extra-bottom' : opener == 'modal'}"> -->
      <div class="grid-container extra-bottom">
        <div v-if="ad.type == 21" class="grid-x">
          <div v-for="(flat, index) in ad.flats" :key="flat.id" class="cell small-12 medium-6 flatswitcher" :class="{active:showFlat == index}" @click="showFlat = index">
            <!-- <label><input v-model="showFlat" type="radio" name="showFlat" :value="index">Lägenhet {{ index+1 }} av 2 {{ flat.street }} {{ flat.street_number }}</label> -->

            <p>Lägenhet {{ index+1 }} av 2: {{ flat.street }} {{ flat.street_number }}</p>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div v-if="!user" class="cell small-12 not-a-member">
            <div class="grid-x grid-margin-x">
              <h2>Är du intresserad av denna bostaden?</h2>
              <p>Detta är en annons för ett bostadsbyte. För att kunna byta din bostad mot denna måste du ha en hyresrätt eller en bostadsrätt samt vara medlem här på sajten.</p>
              <p>
                Medlem blir du genom att klicka på <router-link :to="{name: 'create-ad'}">
                  Lägg in annons
                </router-link>
              </p>
            </div>
          </div>

          <div v-if="thisMatch" class="cell small-12 this-is-a-match">
            <div class="grid-x grid-margin-x">
              <div class="cell small-12 medium-6">
                <h3>Du har ett <span v-if="thisMatch.depth == 'direct'">direktbyte</span><span v-if="thisMatch.depth == 'triangle'">triangelbyte</span> med denna bostaden!</h3>

                <div class="share-url">
                  <span v-clipboard:copy="shareURL" v-tooltip.click="{content:'Kopierad! 📋', delay: 1000}"
                        @focus="$event.target.select()">{{ shareURL }}</span>
                </div>

                <template v-if="thisMatch.depth == 'direct'">
                  <p>Du har ett direktbyte med denna annons, innehavaren är intresserad av din bostad.</p>
                  <p>För att gå vidare i bytesprocessen skall du höra av dig till {{ thisMatch.owner.firstname }}</p>
                  <ol>
                    <li>Bjud in {{ thisMatch.owner.firstname }} att komma på visning i din bostad</li>
                    <li>Bestäm en tid då du kan komma på visning hos {{ thisMatch.owner.firstname }}</li>
                    <li>Om ni efter visningen fortfarande är intresserade av ett byte skall ni kontakta era hyresvärdar och informera om detta samt lämna in en ansökan om byte.</li>
                  </ol>
                </template>

                <template v-if="thisMatch.depth == 'triangle'">
                  <p>Du har ett triangelbyte med denna annonsen. Ett triangelbyte innebär att du flyttar in i denna bostaden, den som bor i den flyttar in i någon av de möjliga matchningarna, och den som bor där flyttar in i din bostad.</p>
                  <p>För att gå vidare i bytesprocessen skall du höra av dig till {{ thisMatch.owner.firstname }}</p>
                  <ol>
                    <li>Bestäm en tid då du kan komma på visning hos {{ thisMatch.owner.firstname }}</li>
                    <li>Bjud in dom som tackat ja till din bostad för att visa den</li>
                    <li>När ni har en bytestriangel klar så lämnar ni in en bytesansökan till era hyresvärdar</li>
                  </ol>
                </template>

                <h3>Är du inte längre intresserad?</h3>
                <button class="button-large secondary" @click.prevent="removeMatch">
                  Ta bort detta byte
                </button>
              </div>
              <div class="cell small-12 medium-6 contact-info">
                <div style="width:100%;">
                  <h3>Kontaktuppgifter för denna bostad</h3>

                  <table class="responsive-card-table unstriped" style="width:100%;">
                    <thead>
                      <tr>
                        <th>Namn</th>
                        <th>Epost</th>
                        <th>Telefon</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-label="Namn">
                          {{ thisMatch.owner.firstname }} {{ thisMatch.owner.lastname }}
                        </td>
                        <td data-label="Epost">
                          <a :href="'mailto:'+thisMatch.owner.email">{{ thisMatch.owner.email }}</a>
                        </td>
                        <td data-label="Telefon">
                          {{ thisMatch.owner.phone }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div v-if="thisMatch.depth=='direct'" style="text-align:center;">
                    <img src="/img/direktbyte.svg" alt="Direktbyte">
                  </div>

                  <div v-if="thisMatch.depth=='triangle'" style="text-align:center;">
                    <img src="/img/triangelbyte.svg" alt="Triangelbyte">
                  </div>
                  <!--
  <p>Namn: {{thisMatch.owner.firstname}} {{thisMatch.owner.lastname}}</p>
<p v-if="thisMatch.owner.phone">Telefon: {{thisMatch.owner.phone}}</p>
<p v-if="thisMatch.owner.email">Epost: {{thisMatch.owner.email}}</p> -->

                  <template v-if="thisMatch.depth=='triangle'">
                    <h3>Följande personer är intresserade av din bostad</h3>

                    <table class="responsive-card-table unstriped" style="width:100%;">
                      <thead>
                        <tr>
                          <th>Namn</th>
                          <th>Epost</th>
                          <th>Telefon</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="match in thisMatch.from_ad_ids" :key="match.id">
                          <td data-label="Namn">
                            {{ match.contact.firstname }} {{ match.contact.lastname }}
                          </td>
                          <td data-label="Epost">
                            <a :href="'mailto:'+match.contact.email">{{ match.contact.email }}</a>
                          </td>
                          <td data-label="Telefon">
                            {{ match.contact.phone }}
                          </td>
                        </tr>
                        <!-- <tr>
      <td data-label="First Name">Peter</td>
      <td data-label="Last Name">Parker</td>
      <td data-label="Hero Title">Spiderman</td>
    </tr>
    <tr>
      <td data-label="First Name">Bruce</td>
      <td data-label="Last Name">Banner</td>
      <td data-label="Hero Title">The Hulk</td>
    </tr>
    <tr>
      <td data-label="First Name">Clark</td>
      <td data-label="Last Name">Kent</td>
      <td data-label="Hero Title">Superman</td>
    </tr> -->
                      </tbody>
                    </table>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- Main -->
          <!-- <select v-model="showFlat">
            <option :key="flat.id" :value="index" />
          </select> -->

          <Flat :key="currentFlat.id" :flat="currentFlat" :ad="ad" :adid="ad.id" @closeModal="$emit('closeModal')" @likecard="$emit('likecard')"
                @passcard="$emit('passcard')" @updateflat="getAd(ad.id)" />

          <!-- Side -->
          <div class="cell small-12 medium-4">
            <div sticky-container class="sticky-container">
              <div
                v-sticky="window.width > 640"
                sticky-offset="offset"
                sticky-side="top"
                class="sticky-inner">
                <aside class="sidebar-wrap">
                  <div v-if="!thisMatch" class="share-url show-for-medium">
                    <span v-clipboard:copy="shareURL" v-tooltip.click="{content:'Kopierad! 📋', delay: 1000}"
                          @focus="$event.target.select()">{{ shareURL }}</span>
                  </div>

                  <FlatDetails :key="currentFlat.id" :flat="currentFlat" />
                  <!-- sidebar-content : END -->
                  <!-- Like & Dislike -->

                  <template v-if="!ad.mine && !thisMatch">
                    <CardLikeButtons
                      v-if="user"
                      class="ad-controls"
                      :active="interest"
                      @likecard="likecard"
                      @passcard="passcard" />

                    <!-- <button @click.prevent="likeOrPass">
                      {{ getLikeStatus }} Testknapp {{ myActiveAd }}
                    </button> -->

                    <!-- <h3 v-if="!user">Du måste vara medlem</h3> -->
                    <!-- Like & Dislike : END -->
                  </template>
                  <template v-if="ad.mine && user">
                    <div class="grid-x grid-margin-x">
                      <!-- <div class="cell small-12">
                      <button class="button-large secondary">
                        Redigera bostad
                      </button>
                    </div> -->
                      <div class="cell small-12">
                        <button
                          class="button-large secondary"
                          @click.prevent="editPhotos">
                          <span v-if="currentFlat.photos.length">Redigera bilder</span>
                          <span v-else>Ladda upp bilder</span>
                        </button>
                      </div>
                      <div class="cell small-12">
                        <button
                          class="button-frameless"
                          @click.prevent="deleteAd(ad.id)">
                          Radera min annons
                        </button>
                      </div>
                    </div>

                    <!--
                  <pre>{{ ad.active }}</pre>
                  <p>
                    Annonsen är aktiv:
                    <ToggleButton
                      :value="ad.active"
                      color="#82C7EB"
                      :sync="true"
                      :labels="{
                        checked: 'Ja',
                        unchecked: 'Nej︎'
                      }"
                    />
                  </p>
                  -->
                  </template>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AdService from '@/services/AdService.js'
import L from 'leaflet'

// import PhotoGrid from '@/components/PhotoGrid.vue'

import CardLikeButtons from '@/components/CardLikeButtons.vue'
import MapView from '@/components/MapView.vue'

// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
// import { setTimeout } from 'timers'

import Flat from '@/components/Flat.vue'
import FlatDetails from '@/components/FlatDetails.vue'

import { mapState, mapGetters } from 'vuex'

const tileProviders = [
  {
    name: 'Wikimedia',
    visible: false,
    url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://foundation.wikimedia.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a>'
  },
  {
    name: 'Cartodb',
    visible: true,
    url:
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
  },
  {
    name: 'OSM',
    visible: false,
    url: 'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://hot.openstreetmap.org/">Humanitarian OpenStreetMap Team</a>'
  }
]

// import Uploader from '@/components/Uploader.vue'

export default {
  components: {
    // LMap,
    // LTileLayer,
    // LMarker,
    // PhotoGrid,
    CardLikeButtons,
    Flat,
    FlatDetails,
    MapView
  },
  props: {
    opener: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    showMap: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

      routeKey: '',
      window: {
        width: 0,
        height: 0
      },
      // adTitle: '',
      interest: false,
      like: false,
      pass: false,
      offset: { top: 84 },
      files: [],
      showFlat: 0,
      signature: {},
      // adExtras,
      adLoaded: false,
      ad: { flats: [] },
      center: { lat: 51.505, lng: -0.09 },
      opacity: 0.6,
      token: 'your token if using mapbox',
      mapOptions: {
        zoomControl: true,
        attributionControl: true,
        zoomSnap: true,
        scrollWheelZoom: false,
        dragging: true,
        tap: false,
        noBlockingAnimations: true
      },
      zoom: 13,
      minZoom: 1,
      maxZoom: 20,
      zoomPosition: 'topleft',
      attributionPosition: 'bottomright',
      layersPosition: 'topright',
      attributionPrefix: 'Vue2Leaflet',
      imperial: false,
      Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],

      icon: L.icon({
        iconUrl: '/img/marker.svg',
        iconSize: [35, 44],
        iconAnchor: [17.5, 44]
      }),
      tileProviders: tileProviders
    }
  },

  computed: {
    ...mapState(['adDetails']),
    ...mapGetters(['matches', 'user', 'myAds', 'myActiveAd']),

    thisMatch() {
      return this.matches.filter(match => match.to_ad_id === this.id)[0]
    },

    adTitle() {
      if (this.ad.flats.length > 0) {
        return this.ad.flats[this.showFlat].rooms + ':a ' + this.ad.flats[this.showFlat].size + '㎡ på ' + this.ad.flats[this.showFlat].street + ' ' + this.ad.flats[this.showFlat].street_number + ' i ' + this.ad.flats[this.showFlat].city
      } else {
        return 'BytBostad'
      }
    },
    ogPhoto() {
      //console.log(this.currentFlat)

      if (this.ad.flats.length > 0 && this.currentFlat.photos.length) {
        return 'https://s3.eu-west-1.amazonaws.com/bytbostad.info/large/' + this.currentFlat.photos[0].img_url
      } else {
        return 'https://bytbostad.se/img/share.jpg'
      }
    },
    currentFlat() {
      return this.ad.flats[this.showFlat]
    },
    modalStyle() {
      if (this.opener !== 'modal') {
        // return 'margin-top:74px;'
        return ''
      } else {
        return 'modalstyle'
      }
    },

    showmap() {
      if (this.ad.lat && this.ad.lng && this.adLoaded) {
        return true
      } else {
        return false
      }
    },

    shareURL() {
      return 'https://bytbostad.se/bostad/' + this.id
    },

    getLikeStatus() {
      if (this.myActiveAd && this.ad.id) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    '$route.params.id': function(id) {
      // console.log(id)
      this.getAd(id)
      this.routeKey = id
    },
    getLikeStatus(newStatus) {
      if (newStatus === true) {
        this.likeOrPass()
      }
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    this.getAd(this.id)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  mounted() {
    // console.log('Monterad')
    // console.log(this.ad)
  },
  methods: {
    removeMatch() {
      const _this = this
      this.$swal.fire({
        title: 'Är du säker?',
        // text: 'Om du tar bort matchningen k',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ff7660',
        // cancelButtonColor: '#61bbb2',
        cancelButtonText: 'Avbryt',
        confirmButtonText: 'Ja, ta bort matchningen!'
      }
      ).then((result) => {
        if (result.value) {
          _this.passcard()
        }
      })
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },

    youNeedAnAd() {
      this.$swal
        .fire({
          title: 'Du har ingen annons!',
          text:
            'För att kunna anmäla intresse eller ointresse på bostäder på BytBostad behöver du ha en annons!',
          type: 'info',
          showCancelButton: false,
          confirmButtonColor: '#64BCB2',
          // cancelButtonColor: '#61bbb2',
          confirmButtonText: 'Skapa annons!',
          cancelButtonText: 'Avbryt'
        })
        .then(result => {
          if (result.value) {
            // console.log(result.value)
            this.$router.push({ name: 'create-ad' })
          } else {
            // console.log(result.value)
          }
        })
    },
    youNeedMembership() {
      this.$swal
        .fire({
          title: 'Aktivera ditt konto',
          text:
            'För att kunna anmäla intresse på bostäder på BytBostad behöver du ha ett aktivt medlemskap!',
          // type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#64BCB2',
          // cancelButtonColor: '#61bbb2',
          confirmButtonText: 'Aktivera ditt konto',
          // cancelButtonText: 'Avbryt'
        })
        .then(result => {
          if (result.value) {
            // console.log(result.value)
            this.$router.push({ name: 'checkout' })
          } else {
            // console.log(result.value)
          }
        })
    },

    handleCardAccepted(id) {
      if (this.myActiveAd) {
        AdService.likeAd(this.myActiveAd, id, 1).then(res => {
          // console.log(res.data)
          if (res.data.data === 'NO_MEMBERSHIP') {
            // alert('Saknar medlemskap')
            this.youNeedMembership()
          }
          this.likeOrPass()
        }).catch(err => {
          // console.log(err)
          throw err
        })
      } else {
        this.youNeedAnAd()
      }
      // AdService.likeProspect
    },
    handleCardRejected(id) {
      const _this = this
      if (this.myAds.length === 1) {
        AdService.passAd(this.myActiveAd, id, 0).then(res => {
          // console.log(res.data)
          _this.$store.dispatch('getMatches', this.myActiveAd)
          this.likeOrPass()
        }).catch(err => {
          // console.log(err)
          throw err
        })
      } else {
        this.youNeedAnAd()
      }
    },

    likecard() {
      if (this.opener === 'modal') {
        this.$emit('likecard')
      } else {
        this.handleCardAccepted(this.ad.id)
      }
      // this.getAd(this.id)
      this.$store.dispatch('getMatches', this.myActiveAd)
      // console.log('Like the card from AD with ' + this.opener)
    },
    passcard() {
      // console.log('Pass the card from AD with ' + this.opener)

      if (this.opener === 'modal') {
        this.$emit('passcard')
      } else {
        this.handleCardRejected(this.ad.id)
      }
      // this.getAd(this.id)
    },
    FBShare() {
      //   console.log('Share on facebook')
      //   FB.ui({
      //   method: 'share',
      //   href: 'https://nya.bytbostad.se'
      // }, function(response){});
      var options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,'
      window.open('https://www.facebook.com/sharer/sharer.php?u=https://bytbostad.se/bostad/' + this.id, '', options + 'height=300,width=600')
    },
    toggleflat(index) {
      // alert(index)
      this.showFlat = index
    },

    getAd(id) {
      AdService.getAd(id)
        .then(response => {
          this.ad = response.data.data
          this.center = {
            lat: parseFloat(response.data.data.lat),
            lng: parseFloat(response.data.data.lng)
          }
          this.adLoaded = true
        })
        .catch(() => {
        // this.error = error
        // this.$store.dispatch('getMyAds')
          this.$router.push({ name: '404' })
        })
    },
    likeProspect() {
      // console.log('Likeprospect from Ad.vue')
      if (this.opener === 'modal') {
        // console.log('Opener was modal')

        // console.log('Modal like')
        // console.log(this.ad.id)
        // this.$emit('closeModal')
        this.$emit('likecard')
      } else {
        // console.log('No opener')
        this.$store.dispatch('likeProspect', this.ad.id)
      }
    },
    passProspect() {
      if (this.opener === 'modal') {
        this.$emit('passcard')
      } else {
        this.$store.dispatch('passProspect', this.ad.id)
      }
    },
    editPhotos() {
      this.$router.push({ name: 'ad-photos', params: { adid: this.ad.id, flatid: this.ad.flats[this.showFlat].id } })
    },
    close() {
      this.$router.go(-1)
    },
    adIsLoaded() {
      this.adLoaded = true
    },
    deleteAd(id) {
      this.$swal
        .fire({
          title: 'Är du säker?',
          text:
            'Om du raderar annonsen så försvinner alla dina matchningar, bilder mm. för denna annonse',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ff7660',
          // cancelButtonColor: '#61bbb2',
          confirmButtonText: 'Ja, radera annonsen!',
          cancelButtonText: 'Avbryt'
        })
        .then(result => {
          if (result.value) {
            // console.log('Ta bort annonsen')
            AdService.deleteAd(id)
              .then(() => {
                this.$store.commit('SET_MY_ADS', [])
                this.$store.dispatch('getMyAds')
                this.$router.push({ name: 'create-ad' })
              })
              .catch(error => {
                Sentry.captureException(error);
                // this.errors = error
              })
          }
        })
    },

    likeOrPass() {
      if (this.myActiveAd !== this.ad.id) {
        AdService.getLikeStatus(this.myActiveAd, this.ad.id).then(res => {
        // console.log('Done!')
        // console.log(res.data.data)
          this.interest = res.data.data
        }).catch(error =>{
          Sentry.captureException(error);
        })
      }
      // console.log(
      //   {
      //     from_ad_id: this.myActiveAd,
      //     to_ad_id: this.ad.id
      //   }
      //   )
      // bbaxios.get('me')
    }
  },

  metaInfo() {
    return {
      title: this.adTitle,
      meta:[
        {
            vmid: 'og:image',
            property: 'og:image',
            name: 'image',
            content: this.ogPhoto,
        },
        {
            vmid: 'og:title',
            name: 'title',
            property: 'og:title',
            content: this.adTitle,
            template: '%s - BytBostad'
          },
        { vmid: 'og:type', property: 'og:type', content: 'website' }

      ]
      // meta: [
      //     {
      //       vmid: 'og:title',
      //       property: 'og:title',
      //       content: this.adTitle,
      //       template: '%s - BytBostad'
      //     },
      //     {
      //       vmid: 'og:image',
      //       property: 'og:image',
      //       content: this.ogPhoto,
      //     }
      // ]
      // meta: [
      // // {http-equiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      // // { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      // { name: 'og:image', content: 'https://s3.eu-west-1.amazonaws.com/bytbostad.info/large/' + this.currentFlat.photos[0].img_url }

      // ]
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/scss/main.scss';

@import '@/scss/abstracts/_variables.scss';
@import '@/scss/abstracts/_mixins.scss';

@import '../../node_modules/leaflet/dist/leaflet.css';
.share-url{
  background-color:white;
font-family: 'Source Code Pro', monospace;
      text-align: center;
    padding: 12px;
  // font-size:10px;

  font-size:12px;
  text-align:center;
  margin-bottom:22px;
  border-radius: 8px;
}

.extra-bottom{
        @include breakpoint(small down){

  padding-bottom:100px;
        }
}
.ad-controls{
      @include breakpoint(small down){

  position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px;
    height: 180px;
    // backdrop-filter: blur(10px);
      }
      .button-card{
        // box-shadow: 0px 0px 15px grey !important;
         box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
      }
}
$responsive-card-table-bottom-gap: 1.5rem;
$responsive-card-table-border: 1px solid black;
$responsive-card-table-stack-point: 640px;
$responsive-card-table-label-bg: gray;

.responsive-card-table {
  border-collapse: collapse;
  font-size:12px;
  background-color:white;
  margin-bottom:20px;

  &.unstriped tbody {
    background-color: transparent;
  }

  th {
    background-color: $responsive-card-table-label-bg;
  }

  tr,
  th,
  td {
    border: $responsive-card-table-border;
  }

td{
  padding:5px;
}
  @media screen and (max-width: $responsive-card-table-stack-point) {
    thead tr {
      position: absolute;
      top: -9999em;
      left: -9999em;
    }

    tr {
      border: $responsive-card-table-border;
      display: block;
    }

    tr + tr {
      margin-top: $responsive-card-table-bottom-gap;
    }
    /* Get table cells to act like rows */

    td {
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: $responsive-card-table-border;
      padding-left: 50%;
      position: relative;

      &:before {
        content: attr(data-label);
        display: inline-block;
        font-weight: bold;
        line-height: 1.5;
        margin-left: -100%;
        width: 100%;
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        background: $responsive-card-table-label-bg;
        width: 45%;
        height: 95%;
        left: 1px;
        top: 1px;
        z-index: 0;
      }
    }
  }
}

.contact-info{
  display:grid;
  justify-items: center;
  align-items: center;
}

.not-a-member{

  background-color:white;
  margin-bottom: 20px;
    border-radius: 8px;
    padding: 20px;

border:5px solid #64bcb2;
p,h1,h2,h3,h4,ul,ol,li{
  margin-bottom:10px;
}
}

// .not-a-member p, .not-a-member ul, .not-a-member ol, .not-a-member li, .not-a-member h3, .not-a-member h1, .not-a-member h2{
//   margin-bottom:10px;
// }

.this-is-a-match{
  border:2px solid #ffb95c;
  // background-color:#ffb95c;
  // background-color: #ffdcad;
  background-color: white;;
  margin-bottom: 20px;
    border-radius: 8px;
    padding: 20px;
}
.this-is-a-match p, .this-is-a-match ul, .this-is-a-match ol, .this-is-a-match li, .this-is-a-match h3{
  margin-bottom:10px;
}
.facebook{
    background: url(/img/fb-white.svg);
    background-color: #3C5A99;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position-y: 50%;
    background-position-x: 25px;

  background-color: #3C5A99;
  padding-left:65px;
}
.facebook:hover{
  background-color: rgb(94, 120, 177);
  /* border: 2px #8b9dc3; */
}
.closebar{
  background-color:white;
}
.flatswitcher{
  // border:1px solid black;

  padding: 20px 10px;
    background-color: #bfbfbf;
    text-align: center;
  //   cursor: pointer;
  //   transition: all 200ms ease-in-out;
  //   font-size: 19px;
  //   font-weight: 700;
    border-radius: 6px;
    margin-bottom:10px;

  //   background-clip: padding-box;

}
.flatswitcher:hover{
  cursor:pointer;
}
.flatswitcher.active{
  background-color:$color-secondary;
      color: #ffffff;

}
</style>
