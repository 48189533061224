<template>
  <main v-if="user" id="page-wrap" class="account">
    <div class="grid-container">
      <div class="grid-x align-center">
        <!-- Main conten inside here -->
        <div class="cell small-12 medium-7">
          <select v-model="apiBase" @change="updateAPI(apiBase)">
            <option value="https://api.sites.test/api/v1">
              Local
            </option>
            <option value="https://api.bytbostad.se/api/v1">
              BB
            </option>
          </select>
        </div>
        <!-- Main conten inside here : END -->
      </div>
    </div>
  </main>
</template>

<script>
// import Pay from '@/components/Pay.vue'
// import LoginBox from '@/components/LoginBox.vue'
// import UserService from '@/services/UserService.js'
import { mapState } from 'vuex'
// import Avatar from '@/components/Avatar.vue'
import FloatLabel from '@/components/FloatLabels.vue'

export default {
  components: {
    // Pay,
    // LoginBox,
    FloatLabel
    // Avatar
  },
  data () {
    return {
      formstatus: '',
      newUser: {},
      formHasChanged: false,
      // user: {},
      apiBase: this.$store.getters.apiBase
    }
  },
  computed: {
    ...mapState(['showFilters', 'user']),
    shortname () {
      if (this.user.firstname) {
        return this.user.firstname.charAt(0)
      } else {
        return '?'
      }
    }
  },
  created () {
    // this.getMe()
  },
  methods: {
    updateAPI (val) {
      this.$store.commit('SET_API', val)
    },
    toggleFilters () {
      this.$store.commit('TOGGLE_FILTERS')
    },
    submitForm () {
      // const newUser = { }
      const { firstname,
        lastname,
        phone,
        street_address,
        careof,
        zipcode,
        city } = this.user
      const updateUser = {
        firstname,
        lastname,
        phone,
        street_address,
        careof,
        zipcode,
        city
      }

      // console.log(updateUser)
      this.$store
        .dispatch('updateMe', updateUser)
        .then(() => {
          this.formHasChanged = false
          this.formSuccess = true
        })
        .catch(error => {
          Sentry.captureException(error);
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/abstracts/_variables.scss';
@import '@/scss/abstracts/_mixins.scss';

input[type='tel'],
input[type='email'],
input[type='text'],
input[type='password'] {
  height: unset;
  outline: none;
  box-shadow: none;
  border: none;
  margin-bottom: 10px;
  &:hover {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

input[type='tel'],
input[type='email'],
input[type='text'],
input[type='password'] {
  width: 100%;
  background-color: #f7f7f7;
  padding: 20px 30px;
  @include br(100px);
  @include transform($transform-base-tempo);
  font-size: 17px;
  &:hover {
    background-color: #f1f1f1;
  }
  &:focus {
    background-color: #f1f1f1;
  }
  &:active {
  }
}

.my-account {
  margin-top: 74px;
}
// label {
//   margin-left: 30px;
//   color: #8a8a8a;
// }

.formcontent {
  background-color: white;
}
</style>
