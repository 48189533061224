<template>
  <main id="page-wrap">
    <div class="grid-container">
      <div class="grid-x grid-margin-x">
        <!-- Main conten inside here -->
        <div class="cell small-12 medium-4">
          <div sticky-container class="sticky-container faq-sidebar">
            <div
              v-sticky
              sticky-offset="offset"
              sticky-side="top"
              class="sticky-inner">
              <aside class="sidebar-wrap">
                <div class="sidebar-content">
                  <header>
                  <!--
                    <h1>Ring oss</h1>
                    <h2><a href="tel:+46812108252">08 - 12 10 82 52</a></h2>
                   -->
                    <h1>Epost</h1>
                    <h2><a href="mailto:info@bytbostad.se" target="_blank">info@bytbostad.se</a></h2>
                    <h1>Facebook</h1>
                    <h2><a href="https://fb.com/bytbostad" target="_blank">@bytbostad</a></h2>
                    <h1>Messenger</h1>
                    <h2><a href="https://fb.me/bytbostad" target="_blank">fb.me/bytbostad</a></h2>
                    <h1>Twitter</h1>
                    <h2><a href="https://twitter.com/bytbostad" target="_blank">@bytbostad</a></h2>
                    <h1>Instagram</h1>
                    <h2><a href="https://instagram.com/bytbostad" target="_blank">@bytbostad</a></h2>
                  </header>
                </div>
              </aside>
            </div>
          </div>
        </div>

        <div class="cell small-12 medium-8">

          
          <section id="qa-wrapper">

            <article class="qa-item">
              <header>
                <h2>Hur funkar detta?</h2>
              </header>
              <p>På <strong>BytBostad</strong> kan du som har en bostad lägga upp den för byte. Du får sedan tacka ja eller nej till olika bostäder som matchar vad du söker.</p>
              <p>När du har en matching för ett direktbyte eller ett triangelbyte så får alla deltagarna i bytet ta del av varandras kontaktuppgifter och kan påbörja ett lägenhetsbyte.</p>
            </article>

            <article class="qa-item">
              <header>
                <h2>Vad kostar det?</h2>
              </header>
              <p>Det är gratis att lägga in sin annons, men för att kunna anmäla intresse på andra bostäder måste du ha ett aktivt medlemskap. Medlemskapet kostar 129 kronor i månaden.</p>
            </article>

            <article class="qa-item">
              <header>
                <h2>Är det garanterat att jag hittar ett byte?</h2>
              </header>
              <p>Vi kan inte garantera ett byte men vi jobbar aktivt på att få in objekt där våra medlemmar söker. Du kan öka chansen att hitta ett byte genom att markera intresse (eller ointresse) för alla bostäder du presenteras för.</p>
            </article>

            <article class="qa-item">
              <header>
                <h2>Kan jag radera mitt konto?</h2>
              </header>
              <p>Självklart kan du radera ditt konto och alla personuppgifter, du gör detta genom att logga in på sajten, välja "Mitt konto" och gå längst ner på sidan, där kan du välja att radera dina uppgifter.</p>
            </article>

            <article class="qa-item">
              <header>
                <h2>Hur ser jag annonser jag sagt JA eller NEJ till?</h2>
              </header>
              <p>
                Gå till <router-link :to="{ name: 'history'}">
                  historik
                </router-link> för att se alla annonser du sagt ja eller nej till.
              </p>
            </article>
          </section>
        </div>
        <!-- Main conten inside here : END -->
      </div>
    </div>
  </main>
</template>

<script>
import FreshDesk from '../components/FreshDesk.vue'

export default {
  components: {FreshDesk},
  data() {
    return {
      offset: {
        top: 114,
        bottom: 100
      }
    }
  },
  metaInfo: {
    // Children can override the title.
    title: 'FAQ',
    meta: [
      { name: 'og:title', content: 'FAQ' },
    ]
  }
}
</script>

<style scoped>
.grid-container {
  margin-top: 74px;
}

p, h2{
  padding-bottom:10px;
}
</style>
