<template>
  <div class="login-form-wrap" :class="loginstatus">
    <div v-if="user">
      <h3>Nu har du nog gått fel..</h3>
      <!-- <h4>{{ uuid }}</h4> -->
    </div>
    <div v-show="!user" class="login-form">
      <template v-if="submitted">
        <h3>Done!</h3>
      </template>

      <template v-else>
        <header>
          <h1>
            <template>{{ $t('onboarding.reset-title') }}</template>
          </h1>
          <h2>
            <template>{{ $t('onboarding.reset-desc') }}</template>
          </h2>
        </header>

        <form
          v-if="loginstatus != 'success'"
          class="login"
          @submit.prevent="submitForm">
          <div class="grid-x">
            <div class="cell small-8">
              <FloatLabel>
                <input
                  v-model="password"
                  name="password"
                  required
                  minlength="6"
                  :type="formtype"
                  :placeholder="$t('onboarding.new-password')"
                  autocomplete="new-password">
              </FloatLabel>
            </div>
            <div class="cell small-4">
              <button class="button hideshow" @click.prevent="pwhidden = !pwhidden">
                <template v-if="pwhidden">
                  🐵Visa
                </template>
                <template v-else>
                  🙈Dölj
                </template>
              </button>
            </div>
          </div>

          <!-- <FloatLabel>
            <input
              v-model="password2"
              name="password2"
              required
              :type="formtype"
              :placeholder="$t('onboarding.repeat-new-password')"
              autocomplete="new-password"
            />
          </FloatLabel> -->

          <button
            id="submit-login"
            type="submit"
            :disabled="loginstatus === 'loading' || password.length < 6"
            class="button-submit secondary">
            {{ $t('onboarding.submit-reset-password') }}
          </button>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FloatLabel from '@/components/FloatLabels.vue'

export default {
  components: {
    FloatLabel
  },
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      submitted: false,
      action: 'login',
      isReady: false,
      username: '',
      password: '',
      password2: '',
      pwhidden: true
    }
  },

  computed: {
    ...mapGetters(['token', 'user', 'loginstatus']),

    // ...mapState(['token', 'user', 'loginstatus'])
    formtype () {
      if (this.pwhidden == true) {
        return 'password'
      } else {
        return 'text'
      }
    }
  },
  methods: {
    submitForm () {
      // Get the update-data from the form
      const updateData = { uuid: this.uuid, password: this.password }

      this.$store.dispatch('newPW', updateData).then(res => {
        // console.log(res.data.data)
        const username = res.data.data
        const password = updateData.password
        // const loginDetails = {email: res.data.data, password : updateData.password}
        this.$store.dispatch('login', { username, password }).then(() => {
          this.$router.push({ name: 'login' })
        })
      }).catch(err => {
        this.$router.push({ name: 'login' })
        // console.log(err)
      })
      // if (this.password === this.password2) {
      //   console.log('Save the new PW')
      // } else {
      //   console.log("Passwords don't match")
      // }
    },

  }
}
</script>

<style scoped>
.hideshow{
  height:60px;background-color:#f7f7f7;color:#9c9c9c;
  width:calc(100% - 10px);
  margin-left:10px;
}
button[disabled] {
    opacity:0.6;
    cursor : not-allowed !important;
}
</style>
