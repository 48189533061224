import posthog from 'posthog-js'

export default {
  install(Vue, options) {
    Vue.prototype.$posthog = posthog.init(
      'phc_xDNVSjmfkWI4uJZTfWRTFzEUz9wG6ElLHry4XqDjcCv',
      {
        api_host: 'https://eu.i.posthog.com'
      }
    )
  }
}
