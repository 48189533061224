import apiClient from '@/services/API'

export default {

  likeAd(from, to, interest) {
    return apiClient().post('/like/', { from, to, interest })
  },
  passAd(from, to, interest) {
    return apiClient().post('/pass/', { from, to, interest })
  },
  getMatches(id) {
    return apiClient().get('/matches/' + id)
  },
  getHistory(id) {
    return apiClient().get('/history/' + id)
  },
  getLikeStatus(from, to) {
    return apiClient().post('/likestatus/', { from, to })
  },
  getProspects() {
    return apiClient().get('/prospects/')
  },
  getAd(id) {
    return apiClient().get('/bostad/' + id)
  },
  createAd(ad) {
    // alert('wohoo')
    return apiClient().post('/bostad/', ad)
  },
  updateFlat(id, flat) {
    // alert('wohoo')
    return apiClient().put('/flats/' + id, flat)
  },
  myAds() {
    return apiClient().get('/minbostad/')
  },
  deleteAd(id) {
    return apiClient().delete('/bostad/' + id)
  },
  getSignature(id) {
    return apiClient().get('/photosign/' + id)
  },
  getPhotos(id) {
    return apiClient().get('/photos/' + id)
  },
  createPhoto(id, photo) {
    return apiClient().post('/photo/' + id, photo)
  },
  deletePhoto(id) {
    return apiClient().delete('/photo/' + id)
  },
  editPhoto(id, photo) {
    return apiClient().patch('/photo/' + id, photo)
  },
  getZip(zip) {
    return apiClient().get('/zip/' + zip)
  }
}
