<template>
  <div>
    <!-- button that shows first -->
    <div v-if="user">
      <!-- <div>Jag är redan inloggad</div> -->

      <form @change="updateUser">
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <p style="margin-bottom:20px;">
              Enbart personer du har matchat med kommer kunna se dina kontaktuppgifter.
            </p>
          </div>

          <!-- first name -->
          <div class="cell small-12 medium-6">
            <FloatLabel>
              <input
                v-model="user.firstname"
                name="firstname"
                placeholder="Förnamn"
                type="text">
            </FloatLabel>
          </div>
          <!-- last name -->
          <div class="cell small-12 medium-6">
            <FloatLabel>
              <input
                v-model="user.lastname"
                name="lastname"
                placeholder="Efternamn"
                type="text">
            </FloatLabel>
          </div>

          <!-- email -->
          <div class="cell small-12">
            <FloatLabel>
              <input

                v-model.trim="user.email"

                name="email"
                placeholder="E-post"
                type="email">
            </FloatLabel>
          </div>

          <!-- phone -->
          <div class="cell small-12">
            <FloatLabel>
              <input
                v-model="user.phone"
                name="phone"
                type="tel"
                placeholder="Telefonnummer">
            </FloatLabel>
          </div>

          <!-- <button class="button" @click.prevent="updateUser">Spara</button> -->

          <!-- <div class="cell small-6">
        <input type="text" v-model="firstname" placeholder="Förnamn">
      </div>

       <div class="cell small-6">
        <input type="text" v-model="lastname" placeholder="Efternamn">
      </div> -->
        </div>
      </form>
    </div>

    <!-- <pre>{{ registered }}</pre> -->

    <!-- <div v-if="!user" class="grid-x options">
      <div class="cell small-12">
        Jag är redan medlem, logga in mig med FB
      </div>

      <div class="cell small-12">
        Jag är redan medlem, logga in mig med epost
      </div>

      <div class="cell small-12">
        Jag är inte medlem, registrera mig med facebook
      </div>

      <div class="cell small-12">
        Jag är inte medlem, registrera mig med epost
        <form>
          <input v-model="email" type="email" placeholder="Epost" @blur="checkEmail">
          <input type="tel" placeholder="Telefonnummer">
          <button class="button">
            Fortsätt
          </button>
        </form>
      </div>
    </div> -->
    <!-- <button class="button">
      Jag är redan medlem
    </button> -->
  </div>
</template>

<script>
import UserService from '@/services/UserService.js'
import FloatLabel from '@/components/FloatLabels.vue'
import { mapGetters } from 'vuex'

export default {
  components: { FloatLabel },
  props: {
  // user: {
  //   type: [Object, Boolean],
  //  default() {
  //     return {}
  //   }
    // }
  },
  data() {
    return {
      email: '',
      registered: '',
      firstname: '',
      lastname: '',
      phone: '',
      // newuser : ...user
      newUser: { ...this.user }

    }
  },
  computed: { ...mapGetters(['user']) },
  methods: {
    copyUser() {
      this.newUser = Object.assign({}, this.user)
    },
    checkEmail() {
    // alert(this.email)
      UserService.checkEmail(this.email).then(res => {
        this.registered = res.data.data
      }).catch(err => {
      // console.log(err)
      })
    },
    updateUser() {
    // alert('hej')
    // console.log(this.firstname)
      const {
        firstname,
        lastname,
        phone,
        email
      } = this.user
      const updateUser = {
        firstname,
        lastname,
        phone,
        email
      }

      // console.log(updateUser)

      //   // console.log(updateUser)
      this.$store.dispatch('updateMe', updateUser).then(() => {
        this.$store.dispatch('getMe')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// .cell{
//     border:1px solid red;
// }

// input[disabled]{
//     //  opacity: 0.4;
//     cursor: not-allowed !important;
// }

input{
  margin-bottom:20px;
}
</style>
