<template>
  <div class="grid-container">
    <div class="grid-x">
      <div class="small-12 medium-12 large-12 cell">
        <form id="wishesForm" @change="updateFilters">
          <div class="grid-x grid-margin-x">
            <!-- <div class="small-12 cell">
              <label for="type">Typ av annons</label>
              <label><input v-model="filters.houseType" type="checkbox" value="1" />Hyresrätt</label>
              <label><input v-model="filters.houseType" type="checkbox" value="2" />Bostadsrätt</label>
            </div> -->
            <div class="cell small-12">
              <h3>{{ $t('filters.your-filters') }}</h3>
            </div>
            <div class="small-12 cell">
              <FloatLabel>
                <select id="maxRent" v-model.number="filters.maxRent">
                  <option value selected disabled>
                    {{ $t('filter.maxrent') }}
                  </option>
                  <option value="3000">
                    3 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="4000">
                    4 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="5000">
                    5 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="6000">
                    6 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="7000">
                    7 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="8000">
                    8 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="9000">
                    9 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="10000">
                    10 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="12000">
                    12 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="14000">
                    14 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="16000">
                    16 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="18000">
                    18 000 kr/ {{ $t('ad.month') }}
                  </option>
                  <option value="20000">
                    20 000 kr/ {{ $t('ad.month') }}
                  </option>

                  <option value="100000">
                    {{ $t('filter.no-upper-limit') }}
                  </option>
                </select>
              </FloatLabel>
            </div>

            <div class="small-12 cell">
              <FloatLabel>
                <select v-model.number="filters.minRooms">
                  <option value selected disabled>
                    {{ $t('filter.min-rooms') }}
                  </option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                </select>
              </FloatLabel>
            </div>

            <div class="small-12 cell">
              <FloatLabel>
                <select v-model.number="filters.minSize">
                  <option value selected disabled>
                    {{ $t('filter.min-size') }}
                  </option>
                  <option value="0">
                    {{ $t('filter.no-lower-limit') }}
                  </option>
                  <option value="30">
                    30 {{ $t('filter.sqmt') }}
                  </option>
                  <option value="40">
                    40 {{ $t('filter.sqmt') }}
                  </option>
                  <option value="50">
                    50 {{ $t('filter.sqmt') }}
                  </option>
                  <option value="60">
                    60 {{ $t('filter.sqmt') }}
                  </option>
                  <option value="70">
                    70 {{ $t('filter.sqmt') }}
                  </option>
                  <option value="80">
                    80 {{ $t('filter.sqmt') }}
                  </option>
                  <option value="90">
                    90 {{ $t('filter.sqmt') }}
                  </option>
                  <option value="100">
                    100 {{ $t('filter.sqmt') }}
                  </option>
                  <option value="120">
                    120 {{ $t('filter.sqmt') }}
                  </option>
                  <option value="150">
                    150 {{ $t('filter.sqmt') }}
                  </option>
                </select>
              </FloatLabel>
            </div>

            <div class="small-12 cell">
              <FloatLabel :label="$t('filter.areas')" :dispatch="false" :fixed="true" class="locationLabel" />

              <Treeselect
                v-if="locations"
                v-model="filters.locationIds"
                :multiple="true"
                :searchable="false"
                :options="locations"
                :value-consists-of="'BRANCH_PRIORITY'"
                :flatten-search-results="true"
                placeholder="Var söker du bostad?"
                :loading="true"
                @input="updateFilters" />
            </div>
          </div>
        </form>
      </div>

      <div class="grid-x">
        <div class="cell"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
import FloatLabel from '@/components/FloatLabels.vue'

// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// import UserService from '@/services/UserService.js'
import { mapState, mapGetters } from 'vuex'

export default {
  components: { Treeselect, FloatLabel },

  data() {
    return {
      msg: ''
      // filters: {},
      // locations: []
    }
  },
  computed: {
    ...mapGetters(['prospectsPhotos']),

    ...mapState(['filters', 'locations'])
  },
  mounted() {
    this.$store.dispatch('getLocations')
  },
  created() {
    if (!this.filters) {
      this.$store.dispatch('getFilters')
    }
    // UserService.getLocations()
    //   .then(response => {
    //     this.locations = response.data.data
    //   })
    //   .catch(error => {
    //     this.errors = error
    //   })
    // if (!this.locations) {
    //   this.$store.dispatch('getLocations')
    // }
  },
  methods: {
    formUpdated() {
      alert('Updated')
    },
    updateFilters() {
      //   console.log('I ran updated filter in MyFilters.vue')
      this.$store.commit('SET_PROSPECTS', [])
      this.$store.commit('SET_PROSPECTS_LOADING', true)
      this.$store.dispatch('setFilters', this.filters).then(() => {
        this.$store.dispatch('updateProspects').then(() => {
          this.$store
            .dispatch('preLoadImages', this.prospectsPhotos)
            .then(() => {
              this.$store.commit('SET_PROSPECTS_LOADING', false)
            })
        })
      })
    }
  }
}
</script>

<style>

.vue-treeselect__control-arrow, .vue-treeselect__option-arrow {
  color:#ff7765 ;
  fill:#ff7765;
}
.vue-treeselect__multi-value-item{
background: #64BCB2;
color:white;
}
.vue-treeselect__value-remove svg{
  color: white;
  fill:white;
}
</style>
<style scoped>

.cell{
  margin-bottom: 20px;
}
.locationLabel{
  margin-bottom:10px;
}

input.vue-treeselect__input{
  padding:unset;
  background-color: unset;
  border-radius: unset;
  font-size: unset;
  height: unset;

}
</style>
