<template>
  <main class="page-wrap">
    <div class="grid-container">
      <slot></slot>
    </div>
  </main>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
// @import '@/scss/abstracts/_variables.scss';
// @import '@/scss/abstracts/_mixins.scss';
@import '@/scss/main.scss';

.page-wrap{
  margin-top: $header-height;
  min-height: calc(100vh - #{$header-height});
  width:100%;
  background-color: #ffffff;
  padding:50px 0;

  @include breakpoint(small down){
      margin-top: $header-mob-height;
        min-height:calc(100vh - #{$header-mob-height + $nav-mob-height}); top:0px;
    }
}

ul{
  margin:0;
}
p, h3, h1, h2, ul{
  padding-bottom:20px;
}
h2,h3,h4{
  padding-top:20px;
}
</style>
