<template>
  <section id="app-card-section">
    <!-- TODO: Make a modal-->
    <!-- The modal of the ad -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="adDetails" class="adModal">
        <Ad
          v-if="adDetails"
          :id="adDetails"
          :opener="'modal'"
          @closeModal="closeModal"
          @likecard="likecard"
          @passcard="passcard"
        />
      </div>
    </transition>

    <!-- The modal of the ad -->
    <div class="grid-container full">
      <div class="grid-x">
        <!-- Map -->
        <!-- <div class="cell medium-4 show-for-medium" style="min-width:300px;max-width:400px;"> -->
        <div v-show="showMap" class="cell small-12 medium-4 show-for-medium">
          <aside id="map-container">
            <!-- {{ prospects }} -->
            <!-- Insert map here -->
            <!-- {{ showMap }} -->
            <LMap
              ref="admap"
              :options="mapOptions"
              :min-zoom="minZoom"
              :max-zoom="maxZoom"
              :zoom="14"
              style="height:100%"
            >
              <LTileLayer
                v-for="tileProvider in tileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                :attribution="tileProvider.attribution"
                layer-type="base"
              />

              <template v-if="currentProspects.length">
                <template v-for="(ad, index) in currentProspects">
                  <LMarker
                    v-if="index === 0"
                    :key="ad.id"
                    :lat-lng="{ lat: ad.lat, lng: ad.lng }"
                    :icon="icon"
                  />
                </template>
              </template>
            </LMap>
          </aside>
        </div>

        <!-- Cardstack and controls -->
        <div
          class="cell small-12 medium-auto align-self-stretch"
          style="overflow:hidden;"
        >
          <div v-show="showFilters" class="slideout">
            <transition name="fade">
              <div
                v-if="showFilters"
                class="slideout-tint"
                @click="toggleFilters"
              ></div>
            </transition>

            <transition name="slide-fade">
              <div v-if="showFilters" class="slideout-content">
                <MyFilters @updated="loadMoreAds" />
              </div>
            </transition>
          </div>

          <!-- <main class="card-elements-wrapper" ref="cardwrap" :style="{paddingLeft: cardwrapSize.padding + 'px', paddingRight: cardwrapSize.padding + 'px', overflow: 'hidden'}"> -->
          <main
            ref="cardwrap"
            class="card-elements-wrapper"
            :style="{ maxWidth: cardWrapperSize + 'px', margin: 'auto' }"
          >
            <div class="card-elemnts-inner">
              <!-- Filter button -->
              <div class="filter-button-wrap">
                <div class="button" @click.prevent="toggleFilters">
                  Filter
                </div>
              </div>

              <div
                v-show="currentProspects.length < 1 && !outOfMatches"
                class="grid-x align-center"
              >
                <div class="cell small-12 medium-8 tinyinfo">
                  <h1 class="center" style="text-align: center;">
                    ⭐️ Bra jobbat ⭐️
                  </h1>
                  <p>
                    Genom att anmäla intresse eller ointresse på lägenheter du
                    hittar här hjälper du våra robotar att skapa matchning. Det
                    är viktigt att du tar ställning till alla objekt för att
                    maximera dina chanser att hitta ett byte!
                  </p>
                  <p>
                    Ju fler som känner till BytBostad desto större chans för dig
                    att hitta det du söker, så tipsa gärna vänner och bekanta om
                    BytBostad!
                  </p>
                </div>
              </div>

              <!-- The card -->
              <div class="grid-x align-center">
                <div
                  v-show="currentProspects.length < 1 && !outOfMatches"
                  class="cell shrink"
                >
                  <button class="button" @click.prevent="loadMoreAds">
                    Ladda in fler annonser
                  </button>
                </div>
                <div
                  v-show="outOfMatches"
                  class="cell small-12 show-for-small-only"
                  style="
    position: fixed;
    top: 72px;
    right: 20px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: auto 50px;
    grid-template-rows: 63px;
    grid-gap: 10px;
"
                >
                  <p
                    style="
    justify-self: right;
    align-self: end;
    font-size: 22px;
"
                  >
                    Ändra sökfilter här
                  </p>
                  <img src="/img/arrow.svg" />
                </div>
                <div
                  v-show="outOfMatches"
                  style="max-width:500px;"
                  class="outOfMatches"
                >
                  <h2>Inga fler annonser just nu</h2>

                  <p>
                    Just nu har vi inte fler annonser som matchar vad du söker.
                    Du kan testa att justera dina filter genom att trycka på
                    "filter"-knappen.
                  </p>
                  <p>
                    Om du är nöjd med ditt filter men ändå inte hittar några
                    fler alternativ, tipsa dina vänner och bekanta om BytBostad.
                    Tipsa dina vänner och kollegor om BytBostad, ta sedan en
                    paus från bostadssökandet och kom tillbaks i morgon!
                  </p>
                </div>
              </div>
              <SwipeCardStack
                ref="stack"
                :new="prospectsLoading"
                :cards.sync="currentProspects"
                :readytoshow.sync="picsReady"
                @cardAccepted="handleCardAccepted"
                @cardRejected="handleCardRejected"
                @cardSkipped="handleCardSkipped"
                @hideCard="removeCardFromDeck"
                @click="showAd"
              />

              <CardLikeButtons
                v-if="currentProspects.length"
                class="card-controls"
                @likecard="likecard"
                @passcard="passcard"
              />
              <KeyboardEvents
                :listen.sync="listenToKeyboard"
                @keydown="keyboardEvent"
              />
            </div>
          </main>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Disables pinch zoom in safari
// document.addEventListener('gesturestart', function(e) {
//   e.preventDefault()
// })

import AdService from '@/services/AdService.js'
import KeyboardEvents from '@/components/KeyboardEvents.vue'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

import SwipeCardStack from '@/components/SwipeCardStack'
// import hello from '@/components/HelloI18n.vue'
import CardLikeButtons from '@/components/CardLikeButtons.vue'
// import AdCard from '@/components/AdCard.vue'
import Ad from '@/views/Ad.vue'
import MyFilters from '@/components/MyFilters.vue'

import { mapGetters } from 'vuex'
import L from 'leaflet'

import store from '@/store'

const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: false,
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  },
  {
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
  },
  {
    name: 'Wikimedia',
    visible: false,
    url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://foundation.wikimedia.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a>'
  },
  {
    name: 'Cartodb',
    visible: true,
    url:
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
  }
]

export default {
  beforeRouteEnter(to, from, next) {
    // console.log('Component based beforeRouteEnter')
    store.commit('SET_AD_DETAILS', false)
    next()
  },
  name: 'App',
  components: {
    SwipeCardStack,
    KeyboardEvents,
    LMap,
    LTileLayer,
    LMarker,
    CardLikeButtons,
    Ad,
    MyFilters
  },

  data() {
    return {
      cardwrapSize: { width: 640, height: 640, padding: 50 },
      // showFilters: false,
      toggleUpdate: true,
      // adDetails: false,
      picsReady: false,
      isReady: false,
      showMap: true,
      map: false,
      // prospects: [],
      center: { lat: 51.505, lng: -0.09 },
      opacity: 0.6,
      // token: 'your token if using mapbox',
      mapOptions: {
        zoomControl: true,
        attributionControl: true,
        zoomSnap: true,
        noBlockingAnimations: true
      },
      zoom: 13,
      minZoom: 1,
      maxZoom: 20,
      zoomPosition: 'topleft',
      attributionPosition: 'bottomright',
      layersPosition: 'topright',
      attributionPrefix: 'Vue2Leaflet',
      imperial: false,
      Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],

      icon: L.icon({
        iconUrl: '/img/marker.svg',
        iconSize: [35, 44],
        iconAnchor: [17.5, 44]
      }),
      tileProviders: tileProviders
    }
  },

  computed: {
    cardWrapperSize() {
      // Get the size
      const { height } = this.cardwrapSize
      return height * 0.9
      // if(height < 600 && width < 610){
      //   return height;
      // }else{
      //   return 610;
      // }
    },
    ...mapGetters([
      'user',
      'listenToKeyboard',
      'prospects',
      'showFilters',
      'prospectsLoading',
      'prospectsPhotos',
      'adDetails',
      'memberships',
      'activeMemberships',
      'myAds',
      'myActiveAd',
      'outOfMatches'
    ]),
    // currentLocation() {
    //   return this.$store.getters.user
    // },
    // isReady() {
    //   return this.prospects.length > 0
    // },
    // listenToKeyboard() {
    //   return this.$store.getters.listenToKeyboard
    // },

    currentProspects() {
      let prospects = this.prospects
      prospects.map(p => (p.isShowing = true))
      return prospects
    }
    // showFilters() {
    //   return this.$store.state.showFilters
    // },
    // adList() {
    //   return this.prospects.map(p => {
    //     return p
    //   })
    // }
  },
  watch: {
    adDetails() {
      if (this.adDetails === true) {
        this.$store.commit('SET_PROSPECT_MODAL', true)
      } else {
        this.$store.commit('SET_PROSPECT_MODAL', false)
      }
    },
    showFilters() {
      if (this.showFilters === true) {
        this.$store.commit('LISTEN_TO_KEYBOARD', false)
      } else {
        this.$store.commit('LISTEN_TO_KEYBOARD', true)
      }
    },
    prospects() {
      if (this.prospects.length > 0) {
        this.showMap = true
        // window.history.pushState(null, null, '/annons/' + this.prospects[0].id)
        this.moveMap()
        // this.showMap = false
        // this.moveMap()
      } else {
        this.$store.commit('LISTEN_TO_KEYBOARD', false)
        // window.history.pushState(null, null, '/annonser/')
        this.showMap = false
      }
    }
  },

  destroyed() {
    try {
      window.removeEventListener('resize', this.getCardwrapSize)
    } catch (err) {
      Sentry.captureException(err)
    }
  },

  created() {
    try {
    window.addEventListener('resize', this.getCardwrapSize)
    } catch (err) {
      Sentry.captureException(err)
    }finally{
          this.$store.commit('LISTEN_TO_KEYBOARD', true)
    }
    
  },
  mounted() {
    this.$store.commit('SET_PROSPECTS_LOADING', true)

    this.$nextTick(() => {
      // console.log('Next tick')
      this.getCardwrapSize()

      this.map = this.$refs.admap.mapObject // work as expected
      // this.map.forceRerender()
      // this.moveMap()
    })
    if (this.prospects.length < 1) {
      this.loadMoreAds()
    } else {
      this.$store
        .dispatch('preLoadImages', this.prospectsPhotos)
        .then(response => {
          this.$store.commit('SET_PROSPECTS_LOADING', false)
          // console.log(response)
        }).catch(error =>{
          Sentry.captureException(error);
        })

      this.moveMap()
      // window.history.replaceState(null, null, '/annons/' + this.prospects[0].id)
    }
    // this.loadMoreAds()

    // if (this.prospects.length) {
    //   this.$store.commit('LISTEN_TO_KEYBOARD', true)
    //   // this.moveMap()
    // }
  },

  methods: {
    getCardwrapSize() {
      const width = this.$refs.cardwrap.clientWidth
      const height = this.$refs.cardwrap.clientHeight
      this.cardwrapSize = { width, height }
    },

    closeModal() {
      this.$store.commit('SET_AD_DETAILS', false)
    },
    likeProspect(id) {
      this.$store.dispatch('likeProspect', id)
    },
    toggleFilters() {
      this.$store.commit('TOGGLE_FILTERS')
    },
    loadMoreAds() {
      this.$store.commit('SET_PROSPECTS_LOADING', true)
      this.$store.dispatch('updateProspects').then(() => {
        this.$store
          .dispatch('preLoadImages', this.prospectsPhotos)
          // .then(response => {
          //   // console.log(response)
          // })
          .catch(error =>{
            Sentry.captureException(error);
          })
      }).catch(error =>{
        Sentry.captureException(error);
      })
    },
    showAd(id) {
      // this.adDetails = id
      this.$store.commit('SET_AD_DETAILS', id)
    },
    moveMap() {
      this.$refs.admap.setCenter({
        lat: this.$store.getters.currentAd.lat,
        lng: this.$store.getters.currentAd.lng
      })
    },
    keyboardEvent(e) {
      if (e.code === 'ArrowLeft') {
        this.$store.commit('SET_AD_DETAILS', false)
        // this.adDetails = false
        this.passcard()
      } else if (e.code === 'ArrowRight') {
        this.$store.commit('SET_AD_DETAILS', false)

        // this.adDetails = false
        this.likecard()
      } else if (e.code === 'Space') {
        if (this.adDetails) {
          // this.adDetails = false
          this.$store.commit('SET_AD_DETAILS', false)
        } else {
          this.showAd(this.prospects[0].id) // this.$router.push({ name: 'ad', params: { id: this.card.id } })
        }
      }
    },
    likecard() {
      this.$store.commit('SET_AD_DETAILS', false)
      // console.log('The like')
      this.$refs.stack.likecard()
    },
    passcard() {
      // console.log('DO THE DODOD')
      this.$store.commit('SET_AD_DETAILS', false)
      // console.log('The pass')
      this.$refs.stack.passcard()
    },
    visitAd() {
      this.$refs.stack.visitAd()
    },

    youNeedAnAd() {
      this.$swal
        .fire({
          title: 'Du har ingen annons!',
          text:
            'För att kunna anmäla intresse eller ointresse på bostäder på BytBostad behöver du ha en annons!',
          type: 'info',
          showCancelButton: false,
          confirmButtonColor: '#64BCB2',
          // cancelButtonColor: '#61bbb2',
          confirmButtonText: 'Skapa annons!',
          cancelButtonText: 'Avbryt'
        })
        .then(result => {
          if (result.value) {
            // console.log(result.value)
            this.$router.push({ name: 'create-ad' })
          } else {
            // console.log(result.value)
          }
        })
    },
    youNeedMembership() {
      this.$swal
        .fire({
          title: 'Aktivera ditt konto',
          text:
            'För att kunna anmäla intresse på bostäder på BytBostad behöver du ha ett aktivt medlemskap!',
          // type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#64BCB2',
          // cancelButtonColor: '#61bbb2',
          confirmButtonText: 'Aktivera ditt konto'
          // cancelButtonText: 'Avbryt'
        })
        .then(result => {
          if (result.value) {
            // console.log(result.value)
            this.$router.push({ name: 'checkout' })
          } else {
            // console.log(result.value)
          }
        })
    },
    handleCardAccepted(id) {
      // console.log('handleCardAccepted')
      // alert(this.activeMemberships.length)
      if (this.myAds.length === 1) {
        // console.log('Gilla: ' + id)
        // console.log('Från: ' + this.myActiveAd)
        // console.log(this.myActiveAd + ' gillar '+ id)
        AdService.likeAd(this.myActiveAd, id, 1)
          .then(res => {
            // console.log(res.data)
            if (res.data.data === 'NO_MEMBERSHIP') {
              // alert('Saknar medlemskap')
              this.youNeedMembership()
            }
          })
          .catch(err => {
            Sentry.captureException(error);
            // console.log(err)
          })
      } else {
        // alert('Du har ingen annons, kan inte gilla: ' + id)
        this.youNeedAnAd()
      }
      // AdService.likeProspect
    },
    handleCardRejected(id) {
      // console.log('handleCardRejected')
      // console.log('Ogilla: ' + id)
      if (this.myAds.length === 1) {
        AdService.passAd(this.myActiveAd, id, 0)
          .then(res => {
            // console.log(res.data)
          })
          .catch(err => {
            Sentry.captureException(err);
            // console.log(err)
          })
      } else {
        // alert('Du har ingen annons, kan inte gilla: ' + id)
        this.youNeedAnAd()
      }
    },
    handleCardSkipped() {
      // console.log('handleCardSkipped')
    },
    removeCardFromDeck() {
      this.$store.commit('REMOVE_PROSPECT')
      // this.prospects.shift()
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://cdn.jsdelivr.net/npm/animate.css@3.5.1');

.outOfMatches h1,
.outOfMatches h2,
.outOfMatches h3,
.outOfMatches p {
  padding-bottom: 20px;
}
.tinyinfo {
  margin-bottom: 30px;
}

.tinyinfo p,
.tinyinfo h1 {
  padding-bottom: 20px;
}
.adModal {
  animation-duration: 0.5s;
  // animation-delay: 2s;
  // animation-iteration-count: infinite;
}

.sidemap {
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
// TODO: Style the adModal

// .adModal {
//   background-color: red;
//   width: 100%;
//   height: 100%;
// }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
