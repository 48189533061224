<template>
  <div>
    <form @submit.prevent="uploadFiles">
      <input
        id="file"
        ref="myFiles"
        accept="image/*"
        type="file"
        class="custom-file-input"
        multiple
        style="display:none;"
        @change="previewFiles">
      <button class="button-large secondary" @click.prevent="$refs.myFiles.click()">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18"><path fill="#FFF" fill-rule="evenodd" d="M22.64876.000043c.625 0 1.15625.21875 1.59375.656248.4375.437499.65625.968748.65625 1.593747v13.499967c0 .624999-.21875 1.156248-.65625 1.593747-.4375.437498-.96875.656248-1.59375.656248H3.14881c-.625 0-1.15625-.21875-1.59375-.656248-.4375-.437499-.65625-.968748-.65625-1.593747V2.250038c0-.624999.21875-1.156248.65625-1.593747.4375-.437498.96875-.656248 1.59375-.656248h19.49995zm-.28125 15.749962c.0625 0 .125-.03125.1875-.093749.0625-.0625.09375-.125.09375-.1875V2.531287c0-.0625-.03125-.125-.09375-.1875-.0625-.062499-.125-.093749-.1875-.093749H3.43006c-.0625 0-.125.03125-.1875.093749-.0625.0625-.09375.125-.09375.1875v12.937469c0 .0625.03125.125.09375.1875.0625.062499.125.093749.1875.093749h18.93745zM6.8988 4.125033c.53125 0 .97656.179687 1.33593.539061.35938.359375.53907.804686.53907 1.335935 0 .531248-.17969.97656-.53907 1.335934-.35937.359374-.80468.539061-1.33593.539061-.53125 0-.97656-.179687-1.33593-.539061-.35938-.359374-.53907-.804686-.53907-1.335934 0-.531249.17969-.97656.53907-1.335935.35937-.359374.80468-.539061 1.33593-.539061zm-1.5 9.374978v-2.249995l1.875-1.874995c.09375-.09375.21875-.140625.375-.140625s.28125.046875.375.140625l1.87499 1.874995 5.62499-5.624986c.09375-.09375.21875-.140625.375-.140625s.28125.046875.375.140625l4.12499 4.12499v3.749991H5.3988z" /></svg>
        Lägg till bilder
      </button>
    </form>
  </div>
</template>

<script>
import AdService from '@/services/AdService.js'
import ImageService from '@/services/ImageService.js'

import axios from 'axios'
import axiosRetry from 'axios-retry'

axiosRetry(axios, {
  retries: 5,
  retryDelay: retryCount => {
    return retryCount * 100
  }
})


const rotation = {
  1: 'rotate(0deg)',
  3: 'rotate(180deg)',
  6: 'rotate(90deg)',
  8: 'rotate(270deg)'
}

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      uploadPercentage: 0,
      files: [],
      awss3: {},
      uploadedFiles: []
    }
  },

  computed: {
    uploadStuff () {
      if (!this.awss3) {
        return {}
      }
      return {
        ...this.awss3.signature
      }
    }
  },
  methods: {
    imageToB64 (file) {
      return URL.createObjectURL(file)
    },
    imageError (id) {
      // There was an image error with: ' + id
      this.uploadedFiles.splice(id, 1)
    },

    uploadFiles () {
      const _this = this
      const s3client = axios.create({
        baseURL: this.awss3.postEndpoint,
        // baseURL: 'https://api.pakorum.com/api/v1',
        withCredentials: false
        // headers: this.uploadStuff
      })
      let files = this.files

      Array.from(files).forEach(file => {
        let previewImage = this.imageToB64(file)
        ImageService.hashFile(file).then(response => {
          let formData = new FormData()
          Object.keys(_this.uploadStuff).forEach(key =>
            formData.append(key, _this.uploadStuff[key])
          )
          formData.append('Content-Type', file.type)
          formData.append('file', file, response)
          let rotationString = ''
          ImageService.getOrientation(file).then(response => {
            rotationString = rotation[response]
          })

          s3client
            .post('', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
              // onUploadProgress: function(progressEvent) {
              //   this.uploadPercentage = parseInt(
              //     Math.round((progressEvent.loaded * 100) / progressEvent.total)
              //   )
              // }.bind(this)
            })

            .then(str => {
              let responseDoc = new DOMParser().parseFromString(
                str.data,
                'application/xml'
              )
              // return responseDoc //responseDoc.getElementsByTagName('Location')[0].textContent
              // let theULR = responseDoc.getElementsByTagName('Location')[0].textContent
              let theKey = responseDoc.getElementsByTagName('Key')[0]
                .textContent
              let fileName = theKey.split('/').pop()
              let ad = theKey.split('/').shift()

              AdService.createPhoto(ad, { fileName })
                .then(response => {
                  _this.$emit('uploaded', {
                    id: response.data.data,
                    style: rotationString,
                    url: previewImage,
                    provider: 'blob',
                    room: 0
                  })
                })
                .catch(error => {
                  // There was an error
                  // console.log(error)
                })
            })
        })
        this.files = []
      })
    },
    previewFiles () {
      AdService.getSignature(this.id)
        .then(response => {
          this.awss3 = response.data.data
          let tempFiles = this.$refs.myFiles.files
          this.files = tempFiles
          const input = this.$refs.myFiles
          input.type = 'text'
          input.type = 'file'
          this.uploadFiles()
        })
        .catch(error => {
          this.files = []
          // There was an error;
          Sentry.captureException(error);
        })

      // this.getSignature()

      // Array.from(this.files).forEach((file, index) => {})
    },

    getSignature () {
      AdService.getSignature(this.id)
        .then(response => {
          this.awss3 = response.data.data
        })
        .catch(error => {
          this.files = []
          // There was an error
          // console.log(error)
          Sentry.captureException(error);
        })
    }
  }
}
</script>

<style scoped>
.button-large{margin:10px 0px 5px 0px;}
.button-large svg{margin-bottom:-3px; margin-right:10px;}
.imagepreview {
  border: 1px solid black;
  /* height: 250px; */
  background-position: center center;
  background-size: cover;
}
.filepicker {
  border: solid 2px black;
  border-radius: 2px;
  cursor: pointer;
}
</style>
