<template>
  <main id="page-wrap">
    <!-- <div class="grid-container"> -->
    <div class="grid-x">
      <!-- Main conten inside here -->

      <div class="cell small-12 medium-6 large-6 hide-for-small-only">
        <section class="login-img">
          <!-- <p>Här väntar</p> -->
          <h1>Ett nytt,<br />bättre hem,<br />väntar dig.</h1>
          <figure class="logo">
            <img src="img/bytbostad_outline.svg" alt="Bytbostad">
          </figure>
        </section>
      </div>

      <div class="cell small-12 medium-6 large-6">
        <div class="login-content">
          <div class="login-form-wrap">
            <LoginBox />
          </div>
        </div>
      </div>

      <!-- Main conten inside here : END -->
    </div>
  </main>
</template>

<script>
import LoginBox from '@/components/LoginBox.vue'

export default {
  components: {
    LoginBox
  },
  data () {
    return {
      loginImg: false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
