<template>
  <SinglePage>
    <div class="grid-x align-center">
      <div class="cell small-12 medium-7">
        <h1>Radera din data</h1>
    <p>Du kan själv radera all data vi har om dig genom att gå till  <router-link :to="{ name: 'my-account'}" target="_blank">mitt konto</router-link>. Längst ned på sidan finns det en knapp för att radera dina uppgifter.</p>
      </div>
    </div>
  </SinglePage>
</template>

<script>
import SinglePage from '@/components/SinglePage.vue'
export default {
  components: {
    SinglePage
  }
}
</script>
