<template>
  <div>
    <!-- <header>
      <h1>Ett litet steg för dig...</h1>
      <p>Ett stort steg för ditt framtida boende!</p>
      <p>
        Gå inte miste om chansen till ett bra byte! Lägg upp en annons idag och
        sök bland tusentals bostäder som passar dina behov.
      </p>
    </header> -->

    <!-- NEW STUFF -->

    <form @submit.prevent="createAd(ad)">
      <h2 v-if="adType==11 || adType==12">
        Bostaden du vill byta bort
      </h2>
      <p>Här fyller du i detaljer om den bostad du vill byta bort. Det är viktigt att detaljerna överensstämmer med din bostad.</p>
      <p>Du måste ha ett förstahandskontrakt för att kunna använda BytBostad.</p>
      <h2 v-if="adType==21">
        Bostad 1 av 2 som du vill byta bort
      </h2>
      <FlatForm :flat.sync="ad[0]" />
      <h2 v-if="adType==21">
        Bostad 2 av 2 som du vill byta bort
      </h2>

      <FlatForm v-if="adType == 21" :flat.sync="ad[1]" />

      <div v-if="!user" class="cell small-12">
        <FloatLabel>
          <input
            :value="user.email"
            name="email"
            type="text"
            placeholder="Epost">
        </FloatLabel>
      </div>

      <h2>Vad söker du?</h2>
      <p>Här fyller du i vad du är ute efter. Du kan alltid ändra dina sökfilter senare!</p>

      <div class="small-12 cell">
        <FloatLabel fixed>
          <select id="maxRent" v-model.number="filters.maxRent" @change="$store.dispatch('setFilters', filters)">
            <option value selected disabled>
              {{ $t('filter.maxrent') }}
            </option>
            <option value="3000">
              3 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="4000">
              4 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="5000">
              5 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="6000">
              6 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="7000">
              7 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="8000">
              8 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="9000">
              9 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="10000">
              10 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="12000">
              12 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="14000">
              14 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="16000">
              16 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="18000">
              18 000 kr/ {{ $t('ad.month') }}
            </option>
            <option value="20000">
              20 000 kr/ {{ $t('ad.month') }}
            </option>

            <option value="100000">
              {{ $t('filter.no-upper-limit') }}
            </option>
          </select>
        </FloatLabel>
      </div>

      <div class="small-12 cell">
        <FloatLabel fixed>
          <select v-model.number="filters.minRooms" @change="updateFilters">
            <option value selected disabled>
              {{ $t('filter.min-rooms') }}
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
          </select>
        </FloatLabel>
      </div>

      <div class="small-12 cell">
        <FloatLabel fixed>
          <select v-model.number="filters.minSize" @change="updateFilters">
            <option value selected disabled>
              {{ $t('filter.min-size') }}
            </option>
            <option value="0">
              {{ $t('filter.no-lower-limit') }}
            </option>
            <option value="30">
              30 {{ $t('filter.sqmt') }}
            </option>
            <option value="40">
              40 {{ $t('filter.sqmt') }}
            </option>
            <option value="50">
              50 {{ $t('filter.sqmt') }}
            </option>
            <option value="60">
              60 {{ $t('filter.sqmt') }}
            </option>
            <option value="70">
              70 {{ $t('filter.sqmt') }}
            </option>
            <option value="80">
              80 {{ $t('filter.sqmt') }}
            </option>
            <option value="90">
              90 {{ $t('filter.sqmt') }}
            </option>
            <option value="100">
              100 {{ $t('filter.sqmt') }}
            </option>
            <option value="120">
              120 {{ $t('filter.sqmt') }}
            </option>
            <option value="150">
              150 {{ $t('filter.sqmt') }}
            </option>
          </select>
        </FloatLabel>
      </div>

      <div class="cell small-12">
        <p>Var söker du bostad?</p>
        <div class="check-tag-wrap">
          <div
            class="check-tag "
            :class="{ active: filterLocationSimple == 'stockholm' }"
            @click="filterLocationSimple = 'stockholm'">
            <div class="check-box">
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8">
                <path
                  fill-rule="nonzero"
                  d="M7.68198052 1.31801905L3.08578644 5.91421312 1.31801948 4.14644617c-.19526188-.19526188-.51184631-.19526046-.70710678 0-.19526046.19526047-.19526188.5118449 0 .70710678L2.73223305 6.9748733c.19526258.19526258.51184631.19526046.70710678 0L8.3890873 2.02512583c.19526046-.19526047.19526258-.5118442 0-.70710678-.19526188-.19526188-.51184632-.19526047-.70710678 0z" />
              </svg>
            </div>
            <div class="check-label">
              Stockholm
            </div>
          </div>

          <div
            class="check-tag "
            :class="{ active: filterLocationSimple == 'goteborg' }"
            @click="filterLocationSimple = 'goteborg'">
            <div class="check-box">
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8">
                <path
                  fill-rule="nonzero"
                  d="M7.68198052 1.31801905L3.08578644 5.91421312 1.31801948 4.14644617c-.19526188-.19526188-.51184631-.19526046-.70710678 0-.19526046.19526047-.19526188.5118449 0 .70710678L2.73223305 6.9748733c.19526258.19526258.51184631.19526046.70710678 0L8.3890873 2.02512583c.19526046-.19526047.19526258-.5118442 0-.70710678-.19526188-.19526188-.51184632-.19526047-.70710678 0z" />
              </svg>
            </div>
            <div class="check-label">
              Göteborg
            </div>
          </div>

          <div
            class="check-tag "
            :class="{ active: filterLocationSimple == 'malmo' }"
            @click="filterLocationSimple = 'malmo'">
            <div class="check-box">
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8">
                <path
                  fill-rule="nonzero"
                  d="M7.68198052 1.31801905L3.08578644 5.91421312 1.31801948 4.14644617c-.19526188-.19526188-.51184631-.19526046-.70710678 0-.19526046.19526047-.19526188.5118449 0 .70710678L2.73223305 6.9748733c.19526258.19526258.51184631.19526046.70710678 0L8.3890873 2.02512583c.19526046-.19526047.19526258-.5118442 0-.70710678-.19526188-.19526188-.51184632-.19526047-.70710678 0z" />
              </svg>
            </div>
            <div class="check-label">
              Malmö
            </div>
          </div>

          <div
            class="check-tag "
            :class="{ active: filterLocationSimple == 'other' }"
            @click="filterLocationSimple = 'other'">
            <div class="check-box">
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8">
                <path
                  fill-rule="nonzero"
                  d="M7.68198052 1.31801905L3.08578644 5.91421312 1.31801948 4.14644617c-.19526188-.19526188-.51184631-.19526046-.70710678 0-.19526046.19526047-.19526188.5118449 0 .70710678L2.73223305 6.9748733c.19526258.19526258.51184631.19526046.70710678 0L8.3890873 2.02512583c.19526046-.19526047.19526258-.5118442 0-.70710678-.19526188-.19526188-.51184632-.19526047-.70710678 0z" />
              </svg>
            </div>
            <div class="check-label">
              Annan ort
            </div>
          </div>

          <div class="clear"></div>
        </div>
      </div>

      <div class="cell small-12">
        <!--
<template v-if="filterLocationSimple != 'other'">
              <input type="radio" v-model="filterLocationSimple" value="stockholm" id="filter_stockholm">
              <label for="filter_stockholm">Stockholm</label>

              <input type="radio" v-model="filterLocationSimple" value="goteborg" id="filter_goteborg">
              <label for="filter_goteborg">Göteborg</label>

              <input type="radio" v-model="filterLocationSimple" value="malmo" id="filter_malmo">
              <label for="filter_malmo">Malmö</label>

                 <input type="radio" v-model="filterLocationSimple" value="other" id="filter_other">
              <label for="filter_other">Annan ort</label>
              </template> -->

        <!-- <div v-if="filterLocationSimple == 'other'" class="small-12 cell"> -->
        <div  class="small-12 cell">
          <FloatLabel :label="$t('filter.areas')" :dispatch="false" :fixed="true" class="locationLabel" />

          <Treeselect
            v-if="locations"
            v-model="filters.locationIds"
            :multiple="true"
            :searchable="false"
            :options="locations"
            :value-consists-of="'BRANCH_PRIORITY'"
            :flatten-search-results="true"
            placeholder="Hela Sverige"
            :loading="true"
            @input="updateFilters" />
        </div>

        <!-- {{filters.locationIds}}
        <hr>
        {{filterLocationSimple}} -->
      </div>

      <div class="cell small-12 grid">
        <button class="button-submit secondary">
          Fortsätt
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdService from '@/services/AdService.js'
// import { Editor, EditorContent } from 'tiptap'
import FloatLabel from '@/components/FloatLabels.vue'
import FlatForm from '@/components/FlatForm.vue'
import Treeselect from '@riophae/vue-treeselect'

export const adExtras = {
  0: { name: 'ERROR', slug: 'ad.ERROR', value: 5 },
  1: { name: 'Bredband', slug: 'ad.broadband', value: 0 },
  2: { name: 'Kabel-TV', slug: 'ad.cable', value: 0 },
  3: { name: 'Hiss', slug: 'ad.elevator', value: 3 },
  4: { name: 'Öppen spis', slug: 'ad.openfire', value: 4 },
  5: { name: 'Badkar', slug: 'ad.bathtub', value: 0 },
  6: { name: 'Dusch', slug: 'ad.shower', value: 0 },
  7: { name: 'Förråd', slug: 'ad.storage', value: 0 },
  8: { name: 'Balkong/Terrass', slug: 'ad.balconyterrace', value: 5 },
  9: { id: 9, name: 'Balkong', slug: 'ad.balcony', value: 5 },
  10: { id: 10, name: 'Terrass', slug: 'ad.terrace', value: 5 },
  11: { id: 11, name: 'Kakelugn', slug: 'ad.tiledstove', value: 5 }
}

export default {
  components: {
    // ckeditor: CKEditor.component,
    // EditorContent,
    FloatLabel,
    FlatForm,
    // FloatingLabel
    Treeselect
  },
  props: {
    adType: {
      type: Number,
      default: 11
    }
  },
  data () {
    return {
      filterLocationSimple: '',
      adExtras: adExtras,
      moneyRent: {
        precision: 0,
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: ' kr / mån'
      },
      moneyPrice: {
        precision: 0,
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: ' kronor'
      },
      json: {},
      html: '',

      // editor: new Editor({
      //   onUpdate: ({ getHTML }) => {
      //     // this.json = getJSON()
      //     this.ad.description = getHTML()
      //   }
      // }),
      // editor: InlineEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // removePlugins: 'toolbar',

        // The configuration of the editor.
        toolbar: [],
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Brödtext',
              class: 'ck-heading_paragraph'
            }
            // {
            //   model: 'heading3',
            //   view: 'h3',
            //   title: 'Rubrik',
            //   class: 'ck-heading_heading3'
            // }
          ]
        }
      },
      ad: [{
        type: 1,
        floor: '',
        details: [],
        description: '',
        rooms: '',
        city: '',
        price: '',
        zipcode: '',
        street: '',
      }, {
        type: 1,
        floor: '',
        details: [],
        description: '',
        rooms: '',
        city: '',
        zipcode: '',
        price: ''
      }]
    }
  },
  mounted () {
    // if (!this.locations) {
    //   this.$store.dispatch('getLocations')
    // }
    this.$store.dispatch('getLocations')
  },
  computed: {
    ...mapGetters(['token', 'user', 'loginstatus', 'filters', 'locations']),
    // zipcode() {
    //   if (this.ad.zipcode) {
    //     if (this.ad.zipcode.toString().length === 5) {
    //       return this.ad.zipcode
    //     } else {
    //       return false
    //     }
    //   } else {
    //     return false
    //   }
    // },
    rentName () {
      if (this.ad.type == 1) {
        return 'Månadshyra'
      } else {
        return 'Månadsavgift'
      }
    },
    landLordName () {
      if (this.ad.type == 1) {
        return 'Hyresvärd'
      } else {
        return 'Namn på BRF'
      }
    },

    filterLocation () {
      if (this.filters.locationIds.length === 1 && this.filters.locationIds[0] == 1) {
        return 1
      }
      // if(this.filters.locationIds.length === 2 && this.filters.locationIds === [69,70]){
      //   return [69,70];
      // }

      if ([69, 70].every(i => this.filters.locationIds.includes(i))) {
        return [69, 70]
      }
      return []
    }
  },
  watch: {

    // filters: {
    //   handler(val){
    //     console.log(val.locationIds)
    //     if(val.locationIds === [1]){
    //       this.filterLocationSimple = 'stockholm'
    //     }
    //   },
    //   deep: true
    // },
    filterLocationSimple: {
      handler (val) {
        if (val === 'stockholm') {
          this.filters.locationIds = [10, 48, 14, 4, 41]
        }

        if (val === 'goteborg') {
          this.filters.locationIds = [69, 70, 71]
        }

        if (val === 'malmo') {
          this.filters.locationIds = [104]
        }

        if (val === 'other') {
          this.filters.locationIds = []
        }
        this.updateFilters()
      }
    },
    zipcode: {
      handler (val) {
        if (val) {
          this.getZip(val)
        }
      }
    }
  },
  methods: {
    selectColor (val) {
      if (!val) {
        return 'color:var(--main-bg-color)'
      }
    },
    isSelected (id) {
      return this.ad.details.includes(id)
      // if (this.ad.details.includes(id)) {
      //   return true
      // } else {
      //   return false
      // }
    },
    toggleExtra (id) {
      if (this.ad.details.includes(id)) {
        this.ad.details = this.ad.details.filter(function (item) {
          return item !== id
        })
      } else {
        this.ad.details.push(id)
      }
    },
    createAd (flats) {
      if (this.adType != 21) {
        flats = flats.slice(0, 1)
      }

      let postAd = { adType: this.adType, flats: flats }

      // console.log(postAd);
      // alert('hello')
      AdService.createAd(postAd)
        .then(response => {
          // this.myAds = response.data.data
          // this.gotoAd(response.data.data)
          this.$store.dispatch('getMyAds')
          // this.$router.push({
          //   name: 'ad-photos',
          //   params: { id: response.data.data }
          // })
          // console.log('Skapat annons');
          // console.log(response.data.data);
        })
        .catch(error => {
          this.errors = error
        })
    },
    // checkZip () {
    //   console.log(this.ad.zipcode)
    // },
    getZip (zip) {
      const _this = this
      AdService.getZip(zip)
        .then(response => {
          _this.ad.city = response.data.data
          // console.log('Got a zip')
          // console.log(response.data.data)
        })
        .catch(error => {
Sentry.captureException(error);
        })
    },

    updateFilters () {
      //   console.log('I ran updated filter in MyFilters.vue')
      this.$store.commit('SET_PROSPECTS', [])
      this.$store.commit('SET_PROSPECTS_LOADING', true)
      this.$store.dispatch('setFilters', this.filters).then(() => {
        this.$store.dispatch('updateProspects').then(() => {
          this.$store
            .dispatch('preLoadImages', this.prospectsPhotos)
            .then(() => {
              this.$store.commit('SET_PROSPECTS_LOADING', false)
            })
        })
      })
    }

  }
}
</script>

<style scoped>

p, h1, h2, input, select{
  margin-bottom:20px;
}

.locationLabel{
  margin-bottom:10px;
  padding-bottom:1px;
}

input.vue-treeselect__input{
  padding:unset;
  background-color: unset;
  border-radius: unset;
  font-size: unset;
  height: unset;
}
.vue-treeselect{
  margin-bottom:20px;
}
.grid{
  display:grid;
  justify-content: center;
}
</style>
