import apiClient from '@/services/API'

export default {
  getFilters () {
    return apiClient().get('/filters/')
  },
  getLocations () {
    return apiClient().get('/locations/')
  },
  updateFilters (filters) {
    return apiClient().post('/filters', filters)
  },
  getMe () {
    return apiClient().get('/users/me')
  },
  updateMe (user) {
    return apiClient().put('/users/me', user)
  },
  checkEmail (email) {
    return apiClient().post('/email', email)
  },
  getMemberships () {
    return apiClient().get('/memberships')
  },
  cancelMembership () {
    return apiClient().post('/cancel-membership')
  },
  deleteUser () {
    return apiClient().delete('/users/me')
  },

}
