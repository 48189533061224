<template>
  <div class="card-stack">
    <transition name="preloader">
      <Preloader v-if="prospectsLoading" />
    </transition>
    <!-- <h1>{{ prospectsLoading }}</h1> -->
    <div class="inner-card-stack">
      <SwipeCard
        v-for="(ad, index) in cards"
        :key="ad.id"
        ref="activecard"
        :card="ad"
        :is-current="index === 0"
        @cardAccepted="$emit('cardAccepted', ad.id)"
        @cardRejected="$emit('cardRejected', ad.id)"
        @cardSkipped="$emit('cardSkipped')"
        @hideCard="$emit('hideCard')"
        @click="click(ad.id)"
        @imageFailed="imageFailed(ad.id)" />

      <!-- Hack: Add 1 card in the card stack without position absolute to expand it to the correct height -->
      <div v-show="cards.length" id="temp-card-push" style="opacity:0;">
        <AdCard v-if="cards[0]" :ad="cards[0]" />
      </div>
    </div>
  </div>
</template>

<script>
import SwipeCard from '@/components/SwipeCard'
import Preloader from '@/components/Preloader'
import AdCard from '@/components/AdCard'
import { mapGetters } from 'vuex'
// import ImageService from '@/services/ImageService.js'

export default {
  components: {
    SwipeCard,
    Preloader,
    AdCard
  },
  props: {
    new: {
      type: Boolean,
      default: false
    },
    cards: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      precentage: 0
    }
  },
  computed: {
    ...mapGetters(['prospectsLoading', 'prospectsPhotos'])
  },

  mounted () {},
  methods: {
    preLoadImages () {
      this.$store.commit('SET_PROSPECTS_LOADING', true)
      this.$store.dispatch('preLoadImages', this.prospectsPhotos).then(() => {
        this.$store.commit('SET_PROSPECTS_LOADING', false)
      })
    },

    imageFailed (id) {
      // this.$Progress.increase(1)
      this.$store.commit('REMOVE_PROSPECT', id)
      // this.$Progress.set(this.prospectPhotos.length)
    },
    loadedOne () {
      // this.$Progress.increase(1)
      // console.log(e.progress)
      // this.precentage = e.progress
    },
    loadedAll () {
      // this.$Progress.finish()
      // console.log('Loaded all')
      // this.$store.commit('SET_PROSPECTS_LOADING', false)
      // this.isReady = true
    },
    click (id) {
      this.$emit('click', id)
    },
    likecard () {
      // this.$refs.activecard.cardTest()
      this.$refs.activecard[0].likecard()
    },
    passcard () {
      // this.$refs.activecard.cardTest()
      this.$refs.activecard[0].passcard()
    }
  }
}
</script>

<style lang="scss" scoped></style>
