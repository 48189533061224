<template>
  <main id="page-wrap" style="padding-top:50px;">
    <div v-if="!myActiveAd" class="grid-container">
      <div class="grid-x align-center">
        <div class="cell small-12 medium-10 " style="padding-bottom:20px;">
          <h1>Du har ingen annons ännu.</h1>
          <p>
            <router-link :to="{ name: 'create-ad' }">
              Lägg in en annons
            </router-link> för att kunna se historik!
          </p>
        </div>
      </div>
    </div>
    <div v-if="myActiveAd" class="grid-container">
      <div class="grid-x align-center">
        <div class="cell small-12 medium-10 " style="padding-bottom:20px;">
          <h1>Historik</h1>
          <p>Här kan du se alla bostäder du tackat ja eller nej till</p>
        </div>
        <!-- Main conten inside here -->

        <div v-if="loading" class="cell small-12 medium-10 ">
          <h2>Laddar...</h2>

          <!-- <button class="button" @click="getHistory">
            Historik för {{ myActiveAd }}
          </button> -->
        </div>
        <div v-if="!loading" class="cell small-12 medium-10 ">
          <!-- <pre>{{ likeStats }}</pre> -->

          <div class="grid-x grid-margin-x">
            <div class="cell small-6 tabs" :class="{ active :liked == 1 }" @click="liked = 1">
              Intressanta ({{ likeStats.liked }})
            </div>
            <div class="cell small-6 tabs" :class="{ active :liked == 0}" @click="liked = 0">
              Ointressanta ({{ likeStats.passed }})
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>Foto</th>
                <th>Adress</th>
                <th class="show-for-medium">
                  Rum
                </th>
                <th class="show-for-medium">
                  Storlek
                </th>
                <th class="show-for-medium">
                  Hyra
                </th>
                <!-- <td class="show-for-medium">Ort</td> -->
                <th>Intresse</th>
              </tr>
            </thead>

            <tbody>
              <!-- {{ liked }} -->
              <router-link v-for="bostad in filteredHistory.slice(0,thelimit)" :key="bostad.id" tag="tr" :to="{ name: 'ad', params: { id: bostad.id } }" class="bostad-item">
                <td class="bostad-photo">
                  <img :src="'https://s3.eu-west-1.amazonaws.com/bytbostad.info/square/' + bostad.image" :alt="bostad.address" @error="errorImage">
                </td>
                <!-- <p>{{bostad}}</p> -->
                <!-- <div>{{ bostad.to_ad_id }}</div> -->
                <td>
                  <p class="address">
                    {{ bostad.address }}
                  </p><span class="city address">{{ bostad.city }}</span>
                </td>
                <td class="show-for-medium rooms">
                  {{ bostad.rooms }}
                </td>
                <td class="show-for-medium size">
                  {{ bostad.size }} m<sup>2</sup>
                </td>
                <td class="show-for-medium rent">
                  {{ bostad.rent | currency }}
                </td>
                <!-- <td class="show-for-medium">{{ bostad.city }}</td> -->
                <td class="interest-select">
                  <!-- <span :class="[!liked ? 'passed' : '']">

  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19"><path fill-rule="evenodd" d="M12.737887 9.2500232L17.816 14.3281361c.304687.3046868.45703.6770819.45703 1.1171849s-.152343.829425-.45703 1.167966l-1.117185 1.117185c-.338541.304687-.727862.45703-1.167965.45703-.440104 0-.812499-.152343-1.117185-.45703l-5.078113-5.0781131-5.078113 5.0781131c-.304687.304687-.677082.45703-1.117185.45703-.440103 0-.829425-.152343-1.167966-.45703L.855103 16.613287c-.304687-.338541-.45703-.727863-.45703-1.167966s.152343-.8124981.45703-1.1171849l5.078113-5.0781129L.855103 4.1719104c-.304687-.3046868-.45703-.6770815-.45703-1.1171849 0-.4401034.152343-.8294248.45703-1.1679659L1.972288.7695747c.338541-.3046868.727863-.4570301 1.167966-.4570301s.812498.1523433 1.117185.4570301l5.078113 5.0781129L14.413665.7695747c.304686-.3046868.677081-.4570301 1.117185-.4570301.440103 0 .829424.1523433 1.167965.4570301L17.816 1.8867596c.304687.3385411.45703.7278625.45703 1.1679659 0 .4401034-.152343.8124981-.45703 1.1171849l-5.078113 5.0781128z"></path></svg>
                  </span> -->
                  <span :class="[liked ? 'liked' : '']">
                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="24"><path fill-rule="evenodd" d="M23.796455 2.4492654c1.049477.8802059 1.777339 1.9635372 2.183588 3.2499922.406249 1.286455.465494 2.5813738.177734 3.8847564-.28776 1.3033825-.905596 2.4290304-1.853511 3.3769451L14.503508 23.117185c-.338541.338541-.727863.507811-1.167966.507811s-.829425-.16927-1.167966-.507811L2.366818 13.0117402C1.418904 12.0299719.801067 10.8873965.513307 9.584014.225547 8.2806314.284792 6.9857126.691041 5.6992576c.406249-1.286455 1.134112-2.3697863 2.183589-3.2499922.91406-.7786438 1.946609-1.2695283 3.097648-1.4726528 1.15104-.2031245 2.285151-.118489 3.402336.2539057s2.098953.9986958 2.945306 1.8789017l1.015622 1.0156226L14.351165 3.10942c.846352-.8802059 1.82812-1.506507 2.945305-1.8789017 1.117185-.3723947 2.251297-.4570302 3.402336-.2539057 1.151039.2031245 2.183588.694009 3.097649 1.4726528z" /></svg>
                  </span>
                </td>
              </router-link>
            </tbody>
          </table>

          <!-- <div style="display:grid;width:100%;margin-bottom:20px;">
<h2 style="text-align:center;">Visar {{ filteredHistory.slice(0,thelimit).length }} av {{ filteredHistory.length}}</h2>
            <button v-if="filteredHistory.slice(0,thelimit).length < filteredHistory.length" class="button" @click.prevent="thelimit = thelimit + 10">Visa fler</button>
</div> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      liked: 1,
      thelimit: 1000,
      loading: true
      // history: [1, 2, 3]
    }
  },
  computed: {
    ...mapGetters(['history', 'myActiveAd']),
    filteredHistory() {
      return this.history.filter(m => m.interest === this.liked)
    },
    likeStats() {
      return {
        liked: this.history.filter(m => m.interest === 1).length,
        passed: this.history.filter(m => m.interest === 0).length,
      }
    }

  },
  watch: {
    myActiveAd: {
      handler() {
        this.getHistory()
      }
    }
  },
  mounted() {
    if (this.history.length) {
      this.loading = false
    }
    if (this.myActiveAd) {
      // console.log('Get history in mount')
      this.getHistory()
    }
  },
  methods: {
    errorImage(event) {
      event.target.src = '/img/nophoto.svg'
    },
    getHistory() {
      // console.log('Historik')
      this.$store.dispatch('getHistory', this.myActiveAd).then(() => {
        // _this.pageLoading = false
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/abstracts/_variables.scss';

h1{
    // color:red;
}

.bostad-item img{
  height:100px;
  width:100px;
}
.bostad-item{
    cursor:pointer;
}

.bostad-item:hover{
    background-color:whitesmoke;
}
.liked{
  // border:1px solid red;
      fill: $color-secondary;

}
.passed{
  // border:1px solid yellow;
      fill: $color-primary;
}
.interest-select{
  fill: #e2e2e2;
  text-align:center;
}

.rent{
  text-align:right;
}

.size{
  text-align:right;
}

.rooms{
  text-align:center;
}

table,td,tr{
  margin:0;
  padding:0;
}

table{
  width:100%;
  margin-top:20px;
  margin-bottom:20px;
}

.address{
  line-height:12px;
  padding-left:10px;
  // padding:10px;
}
.city{
  font-size:12px;
  color:#ABABAB;
  margin:0;
  padding:0;
  padding-left:10px;
}

.bostad-photo{
  width:100px;
  height:100px;

}

.bostad-photo img{
  border-radius:8px;
}

.tabs{
  // background-color:red;
  cursor:pointer;
  padding:20px;
  text-align:center;
  color:#979797;
  border-radius:5px;
  background-color:#e6e6e6;
}
.tabs.active{
    background-color:$color-secondary;
    color:white;

}

thead{

  // background-color:red;
  line-height:50px;
  text-align:center;
}
</style>
