<template>
  <div>
    <!-- Map box -->
    <div class="grid-x">
      <div v-if="ad.flats.length > 1 " class="cell small-12 mapcontainer" :class="{hasMap:showMap}">
        <LMap
          v-if="showMap"
          ref="flatmap"
          class="ad-map"
          :zoom.sync="zoom"
          :options="mapOptions"
          :bounds="bounds"
          :min-zoom="minZoom"
          :max-zoom="maxZoom">
          <LTileLayer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base" />
          <!-- <LMarker :lat-lng="center" :icon="icon" /> -->
          <template v-for="(flat, index) in ad.flats">
            <LMarker v-if="index == currentFlat" :key="flat.id" :lat-lng="{lat: flat.lat, lng: flat.lng}" :icon="icon" @click="showflat(index)" />
            <LMarker v-else :key="flat.id" :lat-lng="{lat: flat.lat, lng: flat.lng}" :icon="iconInactive" @click="showflat(index)" />
          </template>
        </LMap>
      </div>

      <div v-else class="cell small-12 mapcontainer" :class="{hasMap:showMap}">
        <LMap
          v-if="showMap"
          ref="flatmap"
          class="ad-map"
          :zoom.sync="zoom"
          :options="mapOptions"
          :center="{
            lat: ad.flats[0].lat,
            lng: ad.flats[0].lng
          }"
          :min-zoom="minZoom"
          :max-zoom="maxZoom">
          <LTileLayer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base" />
          <!-- <LMarker :lat-lng="center" :icon="icon" /> -->
          <template v-for="(flat, index) in ad.flats">
            <LMarker v-if="index == currentFlat" :key="flat.id" :lat-lng="{lat: flat.lat, lng: flat.lng}" :icon="icon" @click="showflat(index)" />
            <LMarker v-else :key="flat.id" :lat-lng="{lat: flat.lat, lng: flat.lng}" :icon="iconInactive" @click="showflat(index)" />
          </template>
        </LMap>
      </div>
    </div>
  </div>
</template>

<script>

// import { latLngBounds, latLng } from 'leaflet'
import { latLngBounds } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

const tileProviders = [
  {
    name: 'Wikimedia',
    visible: false,
    url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://foundation.wikimedia.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a>'
  },
  {
    name: 'Cartodb',
    visible: true,
    url:
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
  },
  {
    name: 'OSM',
    visible: false,
    url: 'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://hot.openstreetmap.org/">Humanitarian OpenStreetMap Team</a>'
  }
]

export default {

  components: { LMap, LTileLayer, LMarker },
  props: {
    currentFlat: {
      type: Number,
      required: true
    },
    ad: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // center: { lat: 51.505, lng: -0.09 },
      opacity: 0.6,
      token: 'your token if using mapbox',
      mapOptions: {
        zoomControl: true,
        attributionControl: true,
        zoomSnap: true,
        scrollWheelZoom: false,
        dragging: !window.L.Browser.mobile,
        tap: false,
        noBlockingAnimations: true
      },
      zoom: 13,
      minZoom: 1,
      maxZoom: 20,
      zoomPosition: 'topleft',
      attributionPosition: 'bottomright',
      layersPosition: 'topright',
      attributionPrefix: 'Vue2Leaflet',
      imperial: false,
      Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],

      icon: window.L.icon({
        iconUrl: '/img/marker.svg',
        iconSize: [35, 44],
        iconAnchor: [17.5, 44]
      }),

      iconInactive: window.L.icon({
        iconUrl: '/img/marker-disabled.svg',
        iconSize: [35, 44],
        iconAnchor: [17.5, 44],
        // opacity: 0.5
      }),
      tileProviders: tileProviders
    }
  },

  computed: {

    showMap() {
      if (this.ad.flats[0] && this.ad.flats[1]) {
        return this.ad.flats[0].lat != null || this.ad.flats[1].lat != null
      }
      if (this.ad.flats[0]) {
        return this.ad.flats[0].lat != null
      }
      return false
    },
   
    bounds() {
      return latLngBounds(this.ad.flats.map(flat => ([ flat.lat, flat.lng ]))).pad(0.2)

      // return new L.LatLngBounds({ lat: 51.505, lng: -0.09 })
      // return this.ad.flats.map(flat => ([ flat.lat, flat.lng ]))
    }
  },
  watch: {
    currentFlat: {
      handler(val) {
        // if (val) {
        //   this.getZip(val)
        // }
        // console.log(val)

        this.$refs.flatmap.setCenter({
          lat: this.ad.flats[val].lat,
          lng: this.ad.flats[val].lng
        })
      }
    }
  },
  // created() {
  //   const L = window.L
  // },

  // watch: {

  //      currentFlat: {
  //         handler(old,new) {

  //         // this.$refs.flatmap.setCenter({
  //         //   lat: this.ad.flats[i].lat,
  //         //   lng: this.$store.getters.currentAd.lng
  //         // })
  //         console.log(old,new)
  //       }
  //     }
  // },

  methods: {

    // moveMap(id) {
    //   this.$refs.flatmap.setCenter({
    //     lat: this.ad.flats[i].lat,
    //     lng: this.$store.getters.currentAd.lng
    //   })
    // },

    showflat(i) {
      // alert(i)
      this.$emit('toggleflat', i)
    }
  }

}
</script>

<style scoped>

.mapcontainer{
  /* background-color:red; */
  margin-bottom:10px;
}

.hasMap{
  min-height:300px;
}
</style>
