<template>
  <div class>
    <div class="grid-x align-center">
      <div class="cell shrink">
        <!-- Dislike button -->
        <button class="button-card dislike" :class="{active: active === 0, inactive : active === 1}" @click.prevent="$emit('passcard')">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19">
            <path
              fill-rule="evenodd"
              d="M12.737887 9.2500232L17.816 14.3281361c.304687.3046868.45703.6770819.45703 1.1171849s-.152343.829425-.45703 1.167966l-1.117185 1.117185c-.338541.304687-.727862.45703-1.167965.45703-.440104 0-.812499-.152343-1.117185-.45703l-5.078113-5.0781131-5.078113 5.0781131c-.304687.304687-.677082.45703-1.117185.45703-.440103 0-.829425-.152343-1.167966-.45703L.855103 16.613287c-.304687-.338541-.45703-.727863-.45703-1.167966s.152343-.8124981.45703-1.1171849l5.078113-5.0781129L.855103 4.1719104c-.304687-.3046868-.45703-.6770815-.45703-1.1171849 0-.4401034.152343-.8294248.45703-1.1679659L1.972288.7695747c.338541-.3046868.727863-.4570301 1.167966-.4570301s.812498.1523433 1.117185.4570301l5.078113 5.0781129L14.413665.7695747c.304686-.3046868.677081-.4570301 1.117185-.4570301.440103 0 .829424.1523433 1.167965.4570301L17.816 1.8867596c.304687.3385411.45703.7278625.45703 1.1679659 0 .4401034-.152343.8124981-.45703 1.1171849l-5.078113 5.0781128z" />
          </svg>
        </button>
        <!-- Like button -->
        <button class="button-card like" :class="{active : active === 1, inactive : active === 0}" @click.prevent="$emit('likecard')">
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="24">
            <path
              fill-rule="evenodd"
              d="M23.796455 2.4492654c1.049477.8802059 1.777339 1.9635372 2.183588 3.2499922.406249 1.286455.465494 2.5813738.177734 3.8847564-.28776 1.3033825-.905596 2.4290304-1.853511 3.3769451L14.503508 23.117185c-.338541.338541-.727863.507811-1.167966.507811s-.829425-.16927-1.167966-.507811L2.366818 13.0117402C1.418904 12.0299719.801067 10.8873965.513307 9.584014.225547 8.2806314.284792 6.9857126.691041 5.6992576c.406249-1.286455 1.134112-2.3697863 2.183589-3.2499922.91406-.7786438 1.946609-1.2695283 3.097648-1.4726528 1.15104-.2031245 2.285151-.118489 3.402336.2539057s2.098953.9986958 2.945306 1.8789017l1.015622 1.0156226L14.351165 3.10942c.846352-.8802059 1.82812-1.506507 2.945305-1.8789017 1.117185-.3723947 2.251297-.4570302 3.402336-.2539057 1.151039.2031245 2.183588.694009 3.097649 1.4726528z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: [Number, Boolean],
      default: false
    }
  }
  // data() {
  //   return { active: false }
  // }
}
</script>

<style scoped>
.dislike.active{
fill: white;
background-color: #FF7765;
}

.like.active{
fill: white;
background-color: #64BCB2;
}

.dislike.inactive{
fill: #ABABAB;
/* background-color: #979797; */
}

.like.inactive{
fill: #ABABAB;
/* background-color: #; */
}
</style>
