import axios from 'axios'
import store from '@/store'
import router from '@/router'

export default () => {
  const bbaxios = axios.create({
    baseURL:
      localStorage.getItem('apiBase') || 'https://api.bytbostad.se/api/v1',
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: localStorage.getItem('token') || ''
    }
  })

  // Add a response interceptor
  bbaxios.interceptors.response.use(function (response) {
  // console.log('Tillåten request')
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  }, function (error) {
    if (error.response.status === 401) {
      // console.log('Detta var inte tillåtet')
      store.dispatch('logout')
      router.push({ name: 'login' }).catch(err => {
        Sentry.captureException(err);
      })
    }
    // console.log('Felaktig request')

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    return Promise.reject(error)
  })

  return bbaxios
}

// export function intercept(){

//   // Add a response interceptor
//   axios.interceptors.response.use(function (response) {
//     // console.log('APA')
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   return response;
// }, function (error) {

//   if (401 === error.response.status) {
//     console.log('Detta var inte tillåtet')
//     store.dispatch('logout')
//     // router.push({name: 'login'})
//   }
//   // console.log('BJÖRN')

//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error

//   return Promise.reject(error);
// });

// }
