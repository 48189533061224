import Vue from 'vue'

// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// import posthogPlugin from './plugins/posthog'; //import the plugin. 
import posthog from "./plugins/posthog";
import posthogPlugin from "./plugins/posthog";

import App from './App.vue'
import router from './router'
import store from './store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import Meta from 'vue-meta'
// import VueLodash from 'vue-lodash'
// // const loadasOptions = { name: '_' } // customize the way you want to call it
// Vue.use(VueLodash)

import VueGtm from 'vue-gtm'

import Tooltip from 'vue-directive-tooltip'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'

import VueClipboard from 'vue-clipboard2'

import L from 'leaflet'

// import {
//   faUserSecret,
//   faFire,
//   faHome,
//   faSlidersH,
//   faSpinner,
//   faRocket
// } from '@fortawesome/pro-light-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import i18n from './i18n'

// library.add(faUserSecret, faFire, faHome, faSlidersH, faSpinner, faRocket)

// Vue.component('font-awesome-icon', FontAwesomeIcon)

import ImageUploader from 'vue-image-upload-resize'

import VueSweetalert2 from 'vue-sweetalert2'

import Sticky from 'vue-sticky-directive'

import VueTheMask from 'vue-the-mask'

import money from 'v-money'

import imagePreloader from 'vue-image-preloader'

import VueProgressBar from 'vue-progressbar'

import ToggleButton from 'vue-js-toggle-button'

// Sentry.init({
//   environment: process.env.NODE_ENV,
//   release: process.env.__GIT_SHA__,
//   dsn: 'https://ed984dbc0e06408ca9567437b34019fd@sentry.io/1436710',
//   integrations: [
//     new Integrations.Vue({
//       Vue,
//       attachProps: true,
//     }),
//   ],
// })


Sentry.init({
  Vue,
    environment: process.env.NODE_ENV,
  release: process.env.__GIT_SHA__,

  tracingOptions: {
    trackComponents: true,
  },
  dsn: "https://ed984dbc0e06408ca9567437b34019fd@o251030.ingest.sentry.io/1436710",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Vue.use(VueGtm, {
  // id: 'UA-2953232-2', // Your GTM ID
  id: 'GTM-PM4GDW6', // Your GTM ID
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
})


Vue.use(posthogPlugin);
Object.defineProperty(Vue.prototype, "$posthogg", { value: posthog });

Vue.use(Tooltip)
Vue.use(VueClipboard)

Vue.use(Meta)
var _ = require('lodash')
Vue.use(ImageUploader)
Vue.use(VueSweetalert2)
Vue.use(Sticky)
Vue.use(VueTheMask)
// register directive v-money and component <money>
Vue.use(money)
Vue.use(imagePreloader)
Vue.use(VueProgressBar, {
  color: 'rgb(100, 188, 178)',
  failedColor: 'rgb(255, 119, 101)',
  thickness: '3px',
  transition: { speed: '0.2s', opacity: '0.2s', termination: 500 }
})

Vue.filter('currency', function(value) {
  return parseInt(value).toLocaleString('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
})
Vue.use(ToggleButton)


const requireComponent = require.context(
  './components',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})
Vue.config.productionTip = false

window.Sentry = Sentry;
new Vue({
  router,
  store,
  L,
  i18n,
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  // API,
  render: h => h(App),
}).$mount('#app')
