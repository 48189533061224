<template>
  <main v-if="user" id="page-wrap" :key="user.id" class="account">
    <div class="grid-container">
      <div class="grid-x align-center">
        <!-- Main conten inside here -->
        <div class="cell small-12 medium-7">
          <form
            @submit.prevent="submitForm"
            @keyup="formHasChanged = true;formSuccess = false">
            <div class="grid-x grid-margin-x">
              <!-- Title -->
              <div v-if="activeMemberships.length > 0" class="cell small-12">
                <div class="page-divider profile">
                  <h2>Medlemskap</h2>
                  <!-- <p>Test</p> -->
                </div>
                <!-- <div v-if="activeMemberships.length === 0">
                <h1>Inget aktivt</h1>
              </div> -->

                <div v-for="membership in activeMemberships" :key="membership.id" class="grid-x">
                  <!-- <div class="cell small-12"> -->
                  <!-- If the membership IS active AND cancelled -->
                  <template v-if="membershipIsActive(membership) && !membershipCanCancel(membership)">
                    <div class="cell small-8 medium-6">
                      <p>Ditt medlemskap går ut:</p>
                    </div>
                    <div class="cell small-4 medium-6" style="text-align:right;">
                      <p><strong>{{ membership.end_date }}</strong></p>
                    </div>
                  </template>

                  <!-- If the membership IS active and NOT cancelled -->
                  <template v-if="membershipIsActive(membership) && membershipCanCancel(membership)">
                    <div class="cell small-12 medium-6">
                      <p>Ditt medlemskap är aktivt</p>
                    </div>
                    <div class="cell small-12 medium-6">
                      <button v-if="!membership.end_date" class="button cell" style="background-color:#c5c5c5" @click.prevent="cancelMembership">
                        Avsluta medlemskapet
                      </button>
                    </div>
                  </template>
                  <!-- </div> -->
                </div>
              </div>

              <!-- TODO: Add padding down -->
              <div class="cell small-12">
              &nbsp;
              </div>

              <!-- Title -->
              <div class="cell small-12">
                <div class="page-divider profile">
                  <h2>Din profil</h2>
                  <p>
                    Enbart personer du har ett byte med kommer kunna se dina
                    kontaktuppgifter.
                  </p>
                </div>
              </div>
              <!-- avatar -->
              <!-- <div class="cell small-12">
                <Avatar :user="user" :size="120" />
              </div> -->

              <!-- first name -->
              <div class="cell small-6">
                <FloatLabel>
                  <input
                    v-model="user.firstname"
                    name="firstname"
                    placeholder="Förnamn"
                    type="text">
                </FloatLabel>
              </div>
              <!-- last name -->
              <div class="cell small-6">
                <FloatLabel>
                  <input
                    v-model="user.lastname"
                    name="lastname"
                    placeholder="Efternamn"
                    type="text">
                </FloatLabel>
              </div>

              <!-- email -->
              <div class="cell small-12">
                <FloatLabel>
                  <input

                    v-model.trim="user.email"
                    name="email"
                    placeholder="E-post"
                    type="email">
                </FloatLabel>
              </div>

              <!-- phone -->
              <div class="cell small-12">
                <FloatLabel>
                  <input
                    v-model="user.phone"
                    name="phone"
                    type="tel"
                    placeholder="Telefonnummer">
                </FloatLabel>
              </div>
              <template v-if="showInvoiceDetails">
                <!-- Title -->
                <div class="cell small-12">
                  <div class="page-divider address">
                    <h2>Faktureringsadress</h2>
                    <p>
                      Detta är <strong>inte</strong> inställningarna för den
                      bostad du vill byta.
                    </p>
                  </div>
                </div>
                <!-- Street -->
                <div class="cell small-12">
                  <FloatLabel>
                    <input
                      v-model="user.street_address"
                      name="street"
                      type="text"
                      placeholder="Gatuadress">
                  </FloatLabel>
                </div>
                <!-- Care of -->
                <div class="cell small-12">
                  <FloatLabel>
                    <input
                      v-model="user.careof"
                      name="careof"
                      type="text"
                      placeholder="C/O">
                  </FloatLabel>
                </div>
                <!-- Zip -->
                <div class="cell small-6">
                  <FloatLabel>
                    <input
                      v-model.number="user.zipcode"
                      name="zip"
                      type="text"
                      placeholder="Zip">
                  </FloatLabel>
                </div>
                <!-- City -->
                <div class="cell small-6">
                  <FloatLabel>
                    <input
                      v-model="user.city"
                      name="city"
                      type="text"
                      placeholder="City">
                  </FloatLabel>
                </div>
              </template>
              <div class="cell small-12">
                <button class="button-large secondary" type="submit" :disabled="!formHasChanged" :class="{formSuccess: formSuccess}">
                  <span v-if="formSuccess">Uppgifterna uppdaterade</span><span v-else>Spara</span>
                </button>
              </div>

              <!-- Title -->
              <div class="cell small-12">
                <div class="page-divider address">
                  <h2>Radera konto</h2>
                </div>
                <p>Om du raderar ditt konto kommer vi att radera all information vi har sparad om dig, vi kommer även att ta bort alla dina matchningar, informatinon om vilka bostäder du anmält intresse (eller ointresse) för. Detta går inte att ångra!</p>
              </div>
            </div>
          </form>

          <form @submit.prevent="deleteMyAccount">
            <div class="cell small-12">
              <label for="deleteme">
                <input id="deleteme" v-model="deleteAccount" type="checkbox" name="deleteme"> Jag förstår och jag vill radera mitt konto på BytBostad
              </label>
            </div>
            <div v-if="deleteAccount && activeMemberships.length === 0" class="cell small-12">
              <button class="button">
                Radera
              </button>
            </div>

            <div v-if="deleteAccount && activeMemberships.length > 0" class="cell small-12">
              <p>Du har ett aktivt medlemskap. Vänta tills ditt medlemskap gått ut innan du kan radera ditt konto.</p>
            </div>
          </form>
        </div>
        <!-- Main conten inside here : END -->
      </div>
    </div>
    <!-- </div> -->
  </main>
</template>

<script>
// import Pay from '@/components/Pay.vue'
// import LoginBox from '@/components/LoginBox.vue'
// import UserService from '@/services/UserService.js'
import { mapGetters } from 'vuex'
// import Avatar from '@/components/Avatar.vue'
import FloatLabel from '@/components/FloatLabels.vue'

export default {
  components: {
    // Pay,
    // LoginBox,
    FloatLabel
    // Avatar
  },
  data() {
    return {
      formSuccess: false,
      formstatus: '',
      // newUser: {},
      formHasChanged: false,
      // user: {},
      apiBase: this.$store.getters.apiBase,
      deleteAccount: false
      // memberships: []
    }
  },

  computed: {

    hasEmail() {
      // return (this.$store.state.user.email ? true : false)
      return (this.user.email !== '')
    },
    theEmail() {
      return this.$store.state.user.email
    },

    showInvoiceDetails() {
      const {
        street_address,
        careof,
        zipcode,
        city
      } = this.user
      if (street_address || careof || zipcode || city) {
        return true
      } else {
        return false
      }
    },
    ...mapGetters(['showFilters', 'user', 'memberships', 'activeMemberships']),
    shortname() {
      if (this.user.firstname) {
        return this.user.firstname.charAt(0)
      } else {
        return '?'
      }
    }
  },

  created() {
    this.$store
      .dispatch('getMe').catch(() => {
        this.$store.dispatch('logout')
      //       .then(() => {
      //     this.$router.push({ name: 'login'}).catch(err => {})
      // })
      })
  },
  destroyed() {
    this.$store
      .dispatch('getMe')
  },

  methods: {

    deleteMyAccount() {
      this.$store.dispatch('deleteUser').then(() => {
        this.$store.dispatch('logout').then(() => {
          // this.$router.push({ name: 'home'})
        })
      }).catch(() => {
      // console.log(err)
      })
    },

    membershipCanCancel(membership) {
      return (new Date(membership.end_date) > new Date() || membership.end_date == null) && membership.cancellation_date == null
    },
    membershipIsActive(membership) {
      return membership.end_date == null || new Date() < new Date(membership.end_date)
    },
    cancelMembership() {
      this.$swal
        .fire({
          title: 'Är du säker?',
          text: 'Ditt medlemskap kommer att fungera under hela medlemsperioden, men därefter inte förnyas.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ff7660',
          // cancelButtonColor: '#61bbb2',
          confirmButtonText: 'Ja, avsluta medlemskap!',
          cancelButtonText: 'Avbryt'
        })
        .then(result => {
          if (result.value) {
            this.$store.dispatch('cancelMemberships')
          }
        })
    },

    updateAPI(val) {
      this.$store.commit('SET_API', val)
    },
    toggleFilters() {
      this.$store.commit('TOGGLE_FILTERS')
    },
    submitForm() {
      // const newUser = { }
      const {
        firstname,
        lastname,
        phone,
        street_address,
        careof,
        zipcode,
        city,
        email
      } = this.user
      const updateUser = {
        firstname,
        lastname,
        phone,
        street_address,
        careof,
        zipcode,
        city,
        email
      }
      // console.log(updateUser)
      this.$store
        .dispatch('updateMe', updateUser)
        .then(() => {
          this.formHasChanged = false
          this.formSuccess = true
          // console.log(res.data)
          this.$store.dispatch('getMe')
        })
        .catch(() => {
          // console.log('Nope')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/abstracts/_variables.scss';
@import '@/scss/abstracts/_mixins.scss';
button[disabled] {
    opacity:0.4;
    cursor : not-allowed !important;
}
.formSuccess{
  background-color: green;
}
input[type='tel'],
input[type='email'],
input[type='text'],
input[type='password'] {
  height: unset;
  outline: none;
  box-shadow: none;
  border: none;
  margin-bottom: 10px;
  &:hover {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

input:disabled{
    cursor: not-allowed;
}
input[type='tel'],
input[type='email'],
input[type='text'],
input[type='password'] {
  width: 100%;
  background-color: #f7f7f7;
  padding: 20px 30px;
  @include br(100px);
  @include transform($transform-base-tempo);
  font-size: 17px;
  &:hover {
    background-color: #f1f1f1;
  }
  &:focus {
    background-color: #f1f1f1;
  }
}

.my-account {
  margin-top: 74px;
}
// label {
//   margin-left: 30px;
//   color: #8a8a8a;
// }

.formcontent {
  background-color: white;
}

p{
  color: #5B5B5B;
    margin: 0px 0px 14px 0px;
    padding: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
}
</style>
