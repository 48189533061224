<template>
  <div>
    <!-- <div class="card-tags-wrap">
      <div v-for="d in details.slice(0, 3)" :key="d.name" class="card-tag">
        {{ $t(d.slug) }}
      </div>
    </div> -->
    <div class="tag-wrap">
      <span v-for="d in details" :key="d.slug" class="card-tag">
        <!-- {{ adExtras[d] }} -->
        <template v-if="!d == 0">
          {{ $t(adExtras[d].slug) }}
        </template>
      </span>
      <div class="clear"></div>
    </div>
    <!-- <div class="tag-wrap">
      <span class="card-tag">Badkar</span>
      <span class="card-tag">Badkar</span>
      <span class="card-tag">Badkar</span>
      <span class="card-tag">Badkar</span>
      <div class="clear" />
    </div> -->
  </div>
</template>

<script>

export const adExtras = {
  0: { name: 'ERROR', slug: 'ad.ERROR', value: 5 },
  1: { name: 'Bredband', slug: 'ad.broadband', value: 0 },
  2: { name: 'Kabel-TV', slug: 'ad.cable', value: 0 },
  3: { name: 'Hiss', slug: 'ad.elevator', value: 3 },
  4: { name: 'Öppen spis', slug: 'ad.openfire', value: 4 },
  5: { name: 'Badkar', slug: 'ad.bathtub', value: 0 },
  6: { name: 'Dusch', slug: 'ad.shower', value: 0 },
  7: { name: 'Förråd', slug: 'ad.storage', value: 0 },
  8: { name: 'Balkong/Terrass', slug: 'ad.balconyterrace', value: 5 },
  9: { id: 9, name: 'Balkong', slug: 'ad.balcony', value: 5 },
  10: { id: 10, name: 'Terrass', slug: 'ad.terrace', value: 5 },
  11: { id: 11, name: 'Kakelugn', slug: 'ad.tiledstove', value: 5 }

}

export default {
  props: {
    details: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      adExtras
    }
  }

}
</script>

<style>

</style>
