// import axios from 'axios'
// // import { createDeflate } from 'zlib'
// const apiClient = axios.create({
//   baseURL: 'https://api.sites.test/api/v1',
//   // baseURL: 'https://api.pakorum.com/api/v1',
//   withCredentials: false,
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     Token:
//       'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXUyJ9.eyJ1c2VyX2lkIjoiakVlTzlqIiwidGltZXN0YW1wIjoiNTM4NGQ3ODMxY2YzM2E2YjYyYTZkNjFlYjEwMTkzZjI2NDBkM2EzOSIsImlhdCI6IjE1NDkyOTgwNTgifQ.j5_sPvL6g9wokflZhLa7rpRzk3Jc5zHV7qAW-U9BG9r0-ruhSh8EV3D9RQGao_f3EaUJmYtvB34a7SWMPpCItY00mfvzs5khURlEbId_G-68vMrHTcNDYtcnPOSM3a9pf2UXAaZ2kTwB8t5ClPohdn-WPxGOQtCJZnluchBOM0g'
//   }
// })
import SparkMD5 from 'spark-md5'

export default {
  getOrientation (file) {
    return new Promise(resolve => {
      var reader = new FileReader()

      reader.onload = function (event) {
        var view = new DataView(event.target.result)

        if (view.getUint16(0, false) != 0xffd8) return resolve(-2)

        var length = view.byteLength
        var offset = 2

        while (offset < length) {
          var marker = view.getUint16(offset, false)
          offset += 2

          if (marker == 0xffe1) {
            if (view.getUint32((offset += 2), false) != 0x45786966) {
              return resolve(-1)
            }
            var little = view.getUint16((offset += 6), false) == 0x4949
            offset += view.getUint32(offset + 4, little)
            var tags = view.getUint16(offset, little)
            offset += 2

            for (var i = 0; i < tags; i++) {
              if (view.getUint16(offset + i * 12, little) == 0x0112) { return resolve(view.getUint16(offset + i * 12 + 8, little)) }
            }
          } else if ((marker & 0xff00) != 0xff00) break
          else offset += view.getUint16(offset, false)
        }
        return resolve(-1)
      }

      reader.readAsArrayBuffer(file.slice(0, 64 * 1024))
    })
  },
  hashFile (file) {
    return new Promise(resolve => {
      var blobSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice
      //   file = this.files[0],
      var chunkSize = 2097152 // Read in chunks of 2MB
      var chunks = Math.ceil(file.size / chunkSize)
      var currentChunk = 0
      var spark = new SparkMD5.ArrayBuffer()
      var fileReader = new FileReader()

      fileReader.onload = function (e) {
        spark.append(e.target.result) // Append array buffer
        currentChunk++

        if (currentChunk < chunks) {
          loadNext()
        } else {
          resolve(spark.end())
        }
      }

      fileReader.onerror = function () {
        //   console.warn('oops, something went wrong.')
      }

      function loadNext () {
        var start = currentChunk * chunkSize
        var end = start + chunkSize >= file.size ? file.size : start + chunkSize

        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      loadNext()
    })
  }
}
