<template>
  <main id="page-wrap" class="noscroll">
    <!-- <div class="grid-container"> -->
    <div class="grid-x">
      <!-- Main conten inside here -->
      <div class="cell small-12 medium-6">
        <div class="login-img"></div>
      </div>
      <div class="cell small-12 medium-6">
        <div class="login-content">
          <div class="login-form-wrap">
            <ResetBox :uuid="uuid" />
          </div>
        </div>
      </div>
      <!-- Main conten inside here : END -->
    </div>
  </main>
</template>

<script>
import ResetBox from '@/components/ResetBox.vue'

export default {
  components: {
    ResetBox
  },
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loginImg: false
    }
  }
}
</script>

<style lang="scss" scoped></style>
