<template>
  <div id="app">
    <div v-cloak>
      <NavBar />
      <router-view />
      <Footer
        v-if="
          [
            'home',
            'faq',
            'login',
            'ad',
            'my-account',
            'matches',
            'create-ad',
            'about-us',
            'jobs',
            'terms',
            'history',
            'lagenhetsbyte'
          ].includes($route.name)
        "
      />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
// import HelloI18n from './components/HelloI18n'
import { mapGetters } from 'vuex'

export default {
  components: {
    NavBar,
    Footer
  },

  computed: { ...mapGetters(['token', 'myAds', 'myActiveAd']) },

  watch: {
    myAds: {
      handler() {
        if (this.myActiveAd) {
          // console.log(this.myActiveAd)
          this.$store.dispatch('getMatches', this.myActiveAd)
          // this.$router.push({ name: 'ad', params: { id: this.myActiveAd } })
        }

        if (this.myAds.length > 1) {
          // console.log('Du har flera annonser')
        }
      }
    }
  },
  mounted() {
    // console.log('Mounted...')
    if (this.token) {
      this.$store
        .dispatch('getMe')
        .then(res => {
          // console.log('Found me!')
          // console.log(res)
          this.$store.dispatch('getFilters')
          this.$store.dispatch('getMemberships')
          this.$store.dispatch('getMyAds')
          // this.$store.dispatch('getMatches')
          // this.$store.dispatch('getMatches')
        })
        .catch(err => {
          Sentry.captureException(err)
          this.$router.push({ name: 'login' })
          // console.log('Did not find me!')
          // console.log(err)
        })
    }
  },
  metaInfo() {
    return {
      // Children can override the title.
      title: 'Lägenhetsbyte 🏠↔🏠',
      // Result: My Page Title ← My Site
      // If a child changes the title to "My Other Page Title",
      // it will become: My Other Page Title ← My Site
      titleTemplate: '%s - BytBostad',
      // Define meta tags here.
      meta: [
        // {http-equiv: 'Content-Type', content: 'text/html; charset=utf-8'},
        // { name: 'viewport', content: 'width=device-width, initial-scale=1' },

        {
          vmid: 'og:image',
          property: 'og:image',
          name: 'image',
          content: 'https://bytbostad.se/img/share.jpg'
        },
        { property: 'fb:app_id', content: '162588207446399' },
        { vmid: 'og:type', property: 'og:type', content: 'website' }
      ]
    }
  }
}
</script>

<style lang="scss">
// @import '../styles/index.scss';
@import 'scss/main.scss';
[v-cloak] {
  display: none !important;
}
html {
  touch-action: manipulation;
}
</style>
