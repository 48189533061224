<template>
  <div>
    <div class="grid-x grid-margin-x">
      <div v-if="ads.length > 1" class="cell small-12 alert">
        <p>Du kan bara ha en annons åt gången. Radera dom annonser du inte längre har behov av för att kunna använda tjänsten.</p>
      </div>
      <div
        v-for="ad in ads"
        :key="ad.id"
        class="cell small-12 medium-4 bostadsinfo">
        <h2>{{ ad.type|adType }}</h2>
        <span class="subtitle">Annons {{ ad.id }}</span>
        <ul>
          <li v-for="flat in ad.flats" :key="flat.id">
            {{ flat.street }} {{ flat.street_number }}
          </li>
        </ul>
        <!-- <button class="button" @click.prevent="gotoAd(ad.id)">Visa annons</button> -->

        <div class="grid-x grid-margin-x">
          <div class="cell small-6">
            <a :href="'/bostad/' + ad.id" class="button" target="_blank">Visa</a>
          </div>
          <div class="cell small-6">
            <button class="button" @click.prevent="deleteAd(ad.id)">
              Radera
            </button>
          </div>

          <div class="cell small-12 matches">
            <h4 v-if="ad.matches.length">
              {{ ad.matches.length }} matchningar
            </h4>
            <h4 v-else>
              Inga matchningar
            </h4>
          </div>
        </div>

        <!-- <pre>{{ ad }}</pre> -->
        <!-- <ul>
          <li v-for="photo in ad.photos" :key="photo.id">
            {{ photo }}
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
import AdService from '@/services/AdService.js'

export default {
  filters: {
    adType(value) {
      if (value == 11) {
        return 'En mot en'
      } else if (value == 12) {
        return 'En mot två'
      } else if (value == 21) {
        return 'Två mot en'
      }
    }
  },
  props: {
    ads: {
      type: Array,
      required: true
    }
  },
  data() {
    return { matches: '' }
  },
  created() {
    if (this.ads.length === 1) {
      this.gotoAd(this.ads[0].id)
    }
  },
  beforeDestroy() {
    this.$store.commit('SET_TOGGLE_FILTERS', false)
  },
  methods: {
    gotoAd(id) {
      this.$router.push({ name: 'ad', params: { id } })
    },

    deleteAd(id) {
      this.$swal
        .fire({
          title: 'Är du säker?',
          text:
            'Om du raderar annonsen så försvinner alla dina matchningar, bilder mm. för denna annons',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ff7660',
          // cancelButtonColor: '#61bbb2',
          confirmButtonText: 'Ja, radera annonsen!',
          cancelButtonText: 'Avbryt'
        })
        .then(result => {
          if (result.value) {
            // console.log('Ta bort annonsen')
            AdService.deleteAd(id)
              .then(() => {
                this.$store.commit('SET_MY_ADS', [])
                this.$store.dispatch('getMyAds')
                // this.$router.push({ name: 'create-ad' })
              })
              .catch(error => {
                this.errors = error
              })
          }
        })
    }

  }
}

</script>

<style scoped lang="scss">
@import '@/scss/abstracts/_variables.scss';

.bostadsinfo {
  // border: 1px solid black;
  margin-bottom: 10px;
  padding:5px;
  background:white;
  border-radius:5px;
  /* cursor: pointer; */
}

a.button{
  text-decoration: none;
}

button, a.button{
  width:100%;
  text-align: center;
}
.alert{
  font-size:18px;
  background-color: $color-primary;
  padding:25px;
  margin-bottom:25px;
  border-radius:5px;
  color:white;
}

.matches{
  padding:5px;
  text-align: center;
}
.subtitle{
  font-size:14px;
  color: #979797;
}
</style>
