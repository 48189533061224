<template>
  <section id="create-ad">
    <!-- <form @change="$emit(flat)"> -->
    <div class="grid-x grid-margin-x">
      <div class="cell small-12">
        <div class="form-divider">
          <h2>Bostadens adress</h2>
          <!-- <p>Adress till din bostad som du vill byta bort</p> -->
        </div>
      </div>

      <!-- Street -->
      <div class="cell small-12">
        <FloatLabel>
          <input
            v-model.trim="flat.street"
            type="text"
            placeholder="Gatuadress (inklusive nummer)"
            name="street_address"
            required>
        </FloatLabel>
      </div>
      <!-- Zip -->
      <div class="cell small-6">
        <FloatLabel>
          <the-mask
            v-model.number="flat.zipcode"
            :mask="['### ##']"
            type="tel"
            placeholder="Postnummer"
            required
            @keydown="checkZip" />
        </FloatLabel>
      </div>
      <!-- City -->
      <div class="cell small-6">
        <FloatLabel>
          <input
            v-model.trim="flat.city"
            name="city"
            type="text"
            placeholder="Postort"
            required>
        </FloatLabel>
      </div>

      <div class="cell small-12">
        <div class="form-divider">
          <h2>Bostadens detaljer</h2>
        </div>
      </div>

      <!-- <div class="cell small-6">
        <div
          class="radio-wrap"
          :class="{ active: flat.type == 1 }"
          @click="flat.type = 1">
          <div class="radio-box"></div>
          <div class="radio-label">
            Hyresrätt
          </div>
          <div class="clear"></div>
        </div>
      </div>

      <div class="cell small-6">
        <div
          class="radio-wrap"
          :class="{ active: flat.type == 2 }"
          @click="flat.type = 2">
          <div class="radio-box"></div>
          <div class="radio-label">
            Bostadsrätt
          </div>
          <div class="clear"></div>
        </div>
      </div> -->

      <!-- Estate value -->
      <div v-if="flat.type == 2" class="cell small-12 medium-6">
        <FloatLabel v-if="flat.price">
          <money
            v-show="flat.price"
            v-model.number="flat.price"
            v-bind="moneyPrice"
            type="tel"
            name="price"
            placeholder="Pris/Marknadsvärde"
            required />
        </FloatLabel>
        <FloatLabel v-show="!flat.price">
          <input
            v-model.lazy="flat.price"
            name="price"
            type="tel"
            value=""
            placeholder="Pris/Marknadsvärde"
            required>
        </FloatLabel>
      </div>

      <div v-if="flat.type == 2" class="cell small-12 medium-6">
        <p style="padding-bottom:20px;padding-left:20px;padding-right:20px;">
          Om du är osäker på marknadsvärdet kan du kolla slutpriser på t.ex.
          <a href="https://www.booli.se/slutpriser/" target="_blank">Booli</a>
        </p>
      </div>

      <!-- Cost per month -->
      <!-- <div v-if="flat.type == 2" class="cell small-6">
          <FloatLabel v-if="flat.costs">
            <money
              v-if="flat.costs"
              v-model="flat.costs"
              v-bind="moneyRent"
              type="tel"
              name="costs"
              placeholder="Driftkostnad / månad"
            ></money>
          </FloatLabel>
          <FloatLabel v-if="!flat.costs">
            <input
              v-if="!flat.costs"
              v-model.lazy="flat.costs"
              name="costs"
              type="tel"
              placeholder="Driftkostnad / månad"
            />
          </FloatLabel>
        </div> -->

      <!-- Rent -->
      <div class="cell small-6">
        <FloatLabel v-if="flat.rent">
          <money
            v-if="flat.rent"
            v-model="flat.rent"
            v-bind="moneyRent"
            type="tel"
            name="rent"
            :placeholder="rentName"
            required />
        </FloatLabel>
        <FloatLabel v-show="!flat.rent">
          <input
            v-if="!flat.rent"
            v-model.lazy="flat.rent"
            name="rent"
            type="tel"
            :placeholder="rentName"
            required>
        </FloatLabel>
      </div>
      <!-- Size -->
      <div class="cell small-6">
        <FloatLabel>
          <the-mask
            v-model.number="flat.size"
            :mask="['###']"
            type="tel"
            placeholder="Yta (m²)"
            required />
        </FloatLabel>
      </div>
      <!-- Rooms -->
      <div class="cell small-6">
        <!-- <div class="bb-selecet-input">
            Välj antal rum
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7"><path fill-rule="evenodd" d="M5.15624 6.800794L.17187 1.730493C.05729 1.644556 0 1.529973 0 1.386744c0-.143229.04297-.272134.12891-.386718l.34375-.30078C.55859.584662.67318.527371.8164.527371c.14323 0 .27214.057291.38672.171875l4.29687 4.382802L9.79685.699246c.11458-.085938.24349-.128906.38672-.128906s.25781.042968.34375.128906l.34375.30078c.08594.114584.1289.243489.1289.386718s-.04296.257812-.1289.343749L5.8867 6.800794c-.11458.085937-.24348.128906-.38671.128906s-.27214-.042969-.38672-.128906h.04297z"/></svg>
            </span>
          </div> -->

        <FloatLabel>
          <select
            v-model.number="flat.rooms"
            required
            :style="selectColor(flat.rooms)">
            <option value selected disabled>
              Antal rum
            </option>
            <option value="1">
              1 rum
            </option>
            <option value="1.5">
              1,5 rum
            </option>
            <option value="2">
              2 rum
            </option>
            <option value="2.5">
              2,5 rum
            </option>
            <option value="3">
              3 rum
            </option>
            <option value="3.5">
              3,5 rum
            </option>
            <option value="4">
              4 rum
            </option>
            <option value="5">
              5 rum
            </option>
            <option value="6">
              6 rum
            </option>
            <option value="7">
              7 rum
            </option>
            <option value="8">
              8 rum
            </option>
            <option value="9">
              9 rum
            </option>
            <option value="10">
              10 rum
            </option>
            <option value="11">
              11 rum
            </option>
            <option value="12">
              12 rum
            </option>
            <option value="13">
              13 rum
            </option>
            <option value="14">
              14 rum
            </option>
            <option value="15">
              15 rum
            </option>
          </select>
        </FloatLabel>
      </div>
      <!-- Floor -->
      <div class="cell small-6">
        <FloatLabel :dispatch="false">
          <select
            v-model.number="flat.floor"
            required
            :style="selectColor(flat.floor)">
            <option value selected disabled>
              Välj våning
            </option>
            <option value="0">
              Bottenvåning eller lägre
            </option>
            <option v-for="floors in 54" :key="floors">
              {{ floors }}
            </option>
          </select>
        </FloatLabel>
      </div>

      <div class="cell small-12">
        <FloatLabel>
          <input
            v-model.trim="flat.landlord"
            type="text"
            :placeholder="landLordName"
            required>
        </FloatLabel>
      </div>

      <div class="cell small-12">
        <div class="check-tag-wrap">
          <div
            v-for="(e, index) in adExtras"
            :key="e.id"
            class="check-tag "
            :class="{ active: isSelected(Number(index)) }"
            @click="toggleExtra(Number(index))">
            <div class="check-box">
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8">
                <path
                  fill-rule="nonzero"
                  d="M7.68198052 1.31801905L3.08578644 5.91421312 1.31801948 4.14644617c-.19526188-.19526188-.51184631-.19526046-.70710678 0-.19526046.19526047-.19526188.5118449 0 .70710678L2.73223305 6.9748733c.19526258.19526258.51184631.19526046.70710678 0L8.3890873 2.02512583c.19526046-.19526047.19526258-.5118442 0-.70710678-.19526188-.19526188-.51184632-.19526047-.70710678 0z" />
              </svg>
            </div>
            <div class="check-label">
              {{ e.name }}
            </div>
          </div>

          <div class="clear"></div>
        </div>
      </div>

      <div class="cell small-12">
        <div class="form-divider">
          <h2>Bostadsbeskrivning</h2>
          <p>Beskriv bostaden så gott du kan. Berätta om skick, de olika rummen etc.</p>
        </div>
      </div>

      <div class="cell small-12">
        <label for="ad_description">
          <!-- Beskrivning (Skriv gärna minst 300 tecken, just nu har du skrivit -->
          <!-- {{ flat.description.length }} -->
        </label>
        <EditorContent
          v-model="flat.description"
          :editor="editor"
          placeholder="Beskriv bostaden" />
      </div>
    </div>
    <!-- </form> -->
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import AdService from '@/services/AdService.js'
import { Editor, EditorContent } from 'tiptap'
import FloatLabel from '@/components/FloatLabels.vue'

export const adExtras = {
//   0: { id: 0, name: 'ERROR', slug: 'ad.ERROR', value: 5, show: false},
//   1: { id: 1, name: 'Bredband', slug: 'ad.broadband', value: 0 },
//   2: { id: 2, name: 'Kabel-TV', slug: 'ad.cable', value: 0 },
  3: { id: 3, name: 'Hiss', slug: 'ad.elevator', value: 3 },
  4: { id: 4, name: 'Öppen spis', slug: 'ad.openfire', value: 4 },
  5: { id: 5, name: 'Badkar', slug: 'ad.bathtub', value: 0 },
  //   6: { id: 6, name: 'Dusch', slug: 'ad.shower', value: 0 },
  7: { id: 7, name: 'Förråd', slug: 'ad.storage', value: 0 },
  //   8: { id: 8, name: 'Balkong/Terrass', slug: 'ad.balconyterrace', value: 5 }
  9: { id: 9, name: 'Balkong', slug: 'ad.balcony', value: 5 },
  10: { id: 10, name: 'Terrass', slug: 'ad.terrace', value: 5 },
  11: { id: 11, name: 'Kakelugn', slug: 'ad.tiledstove', value: 5 }

}

export default {
  components: {
    // ckeditor: CKEditor.component,
    EditorContent,
    FloatLabel
    // FloatingLabel
  },

  props: {
    flat: {
      type: Object,

      default: () => {}

      //   default: (){

      //   return () => {

      //   }
      //    }

    },
  },
  data () {
    return {
      // flat: {

      // type: 1,
      // floor: '',
      // details: [],
      // description: '',
      // rooms: '',
      // city: '',
      // price: '',
      // zipcode: '',
      // street: '',

      // },
      adExtras: adExtras,
      moneyRent: {
        precision: 0,
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: ' kr / mån'
      },
      moneyPrice: {
        precision: 0,
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: ' kronor'
      },
      json: {},
      html: '',

      editor: new Editor({
        onUpdate: ({ getHTML }) => {
          // this.json = getJSON()
          this.flat.description = getHTML()
        }
      }),
      // editor: InlineEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // removePlugins: 'toolbar',

        // The configuration of the editor.
        toolbar: [],
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Brödtext',
              class: 'ck-heading_paragraph'
            }
            // {
            //   model: 'heading3',
            //   view: 'h3',
            //   title: 'Rubrik',
            //   class: 'ck-heading_heading3'
            // }
          ]
        }
      },
    //   ad: {
    //     type: 1,
    //     floor: '',
    //     details: [],
    //     description: '',
    //     rooms: '',
    //     city: '',
    //     price: ''
    //   }
    }
  },
  computed: {
    ...mapGetters(['token', 'user', 'loginstatus']),
    zipcode () {
      if (this.flat.zipcode) {
        if (this.flat.zipcode.toString().length === 5) {
          return this.flat.zipcode
        } else {
          return false
        }
      } else {
        return false
      }
    },
    rentName () {
      if (this.flat.type == 1) {
        return 'Månadshyra'
      } else {
        return 'Månadsavgift'
      }
    },
    landLordName () {
      if (this.flat.type == 1) {
        return 'Hyresvärd'
      } else {
        return 'Namn på BRF'
      }
    }
  },
  watch: {
    zipcode: {
      handler (val) {
        if (val) {
          this.getZip(val)
        }
      }
    }
  },

  mounted () {
    var elements = document.querySelectorAll('input,select,textarea')
    var invalidListener = function () { this.scrollIntoView(false) }
    for (var i = elements.length; i--;) { elements[i].addEventListener('invalid', invalidListener) }
  },
  methods: {
    selectColor (val) {
      if (!Number.isInteger(val)) {
        return 'color:var(--main-bg-color)'
      }
    },
    isSelected (id) {
      return this.flat.details.includes(id)
      // if (this.flat.details.includes(id)) {
      //   return true
      // } else {
      //   return false
      // }
    },
    toggleExtra (id) {
      if (this.flat.details.includes(id)) {
        this.flat.details = this.flat.details.filter(function (item) {
          return item !== id
        })
      } else {
        this.flat.details.push(id)
      }
    },
    createAd (ad) {
      // alert('hello')
      AdService.createAd(ad)
        .then(response => {
          // this.myAds = response.data.data
          // this.gotoAd(response.data.data)
          this.$store.dispatch('getMyAds')
          this.$router.push({
            name: 'ad-photos',
            params: { id: response.data.data }
          })
        })
        .catch(error => {
          this.errors = error
        })
    },
    checkZip () {
      // console.log(this.flat.zipcode)
    },
    getZip (zip) {
      const _this = this
      AdService.getZip(zip)
        .then(response => {
          _this.flat.city = response.data.data
          // console.log('Got a zip')
          // console.log(response.data.data)
        })
        .catch(error => {
          // console.log(error)
        })
    }
  }
}
</script>

<style scoped>
</style>
