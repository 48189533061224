import { render, staticRenderFns } from "./FBButton.vue?vue&type=template&id=301dd55b&scoped=true&"
import script from "./FBButton.vue?vue&type=script&lang=js&"
export * from "./FBButton.vue?vue&type=script&lang=js&"
import style0 from "./FBButton.vue?vue&type=style&index=0&id=301dd55b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301dd55b",
  null
  
)

export default component.exports