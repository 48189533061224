<template>
  <div></div>
</template>

<script>
export default {
  computed: {
    listen () {
      return this.$store.getters.listenToKeyboard
    }
  },
  watch: {
    listen: {
      handler () {
        if (this.listen == true) {
          this.startListen()
        } else {
          this.stopListen()
        }
      }
    }
  },
  created () {
    if (this.listen) {
      this.startListen()
    }
  },
  beforeDestroy () {
    this.stopListen()
  },
  methods: {
    startListen () {
      const component = this
      this.handler = function (e) {
        // component.$emit('keyup', e)
        component.$emit('keydown', e)
      }
      // window.addEventListener('keyup', this.handler)
      window.addEventListener('keydown', this.handler)
    },
    stopListen () {
      // window.removeEventListener('keyup', this.handler)
      window.removeEventListener('keydown', this.handler)
    }
  }
}
</script>

<style scoped>
div {
  display: none;
}
</style>
