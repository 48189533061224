<template>
  
  <div class="ad-image-list">
    <!-- <PhotoItem
        v-for="photo in photos"
        :photo="photo"
        :key="photo.id"
      ></PhotoItem> -->

    <!-- <gallery :images="images" :index="index" @close="index = null"></gallery> -->

    <image-preloader
      :srcs="imageList"
      @loaded="loadedOne"
      @loaded-all="loadedAll" />
    <div
      v-for="(image, imageIndex) in images"
      :key="imageIndex"
      :class="{ 'ad-single-img': !featured, 'ad-featured-img': featured }"
      @click="index = imageIndex">
      <!-- <div >Byt annonsbild</div> -->
      <router-link
        v-if="featured && mine"
        class="featured-opt"
        tag="div"
        :to="{ name: 'ad-photos', params: { flatid } }">
        Byt annonsbild
      </router-link>

      <div v-if="image.show">
        <img :src="image.thumbnail" @error="imageError">
      </div>

      <div v-if="!image.show" style="padding-top:56.25%;position:relative">
        <Preloader style="background-color:#ffffff;" />
      </div>
      <!-- <img v-if="image.show" :src="image.thumbnail" />
      <div v-else style="width:640px;height:480px;" />
      <Preloader />
      -->
      <div v-if="image.room || image.description" class="ad-image-caption">
        <!-- <pre>{{ photoRoom }}</pre> -->
        <span v-if="image.room">{{ photoRoom[image.room].name }}<template v-if="image.description">:</template></span>
        <template v-if="image.description">
          {{ image.description }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader'
// import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'

export const photoRoom = {
  // 0: { name: 'Vad föreställer bilden?', slug: 'photo.what' },
  1: { id: 1, name: 'Sovrum', slug: 'photo.bedroom' },
  2: { id: 2, name: 'Vardagsrum', slug: 'photo.livingroom' },
  3: { id: 3, name: 'Kök', slug: 'photo.kitchen' },
  4: { id: 4, name: 'Hall', slug: 'photo.hallway' },
  5: { id: 5, name: 'Badrum', slug: 'photo.bathroom' },
  6: { id: 6, name: 'Toalett', slug: 'photo.toilet' },
  7: { id: 7, name: 'Förråd', slug: 'photo.storage' },
  8: { id: 8, name: 'Balkong', slug: 'photo.balcony' },
  9: { id: 9, name: 'Terrass', slug: 'photo.terrace' },
  10: { id: 10, name: 'Vind', slug: 'photo.attic' },
  11: { id: 11, name: 'Trappuppgång', slug: 'photo.entrance' },
  12: { id: 12, name: 'Uteplats', slug: 'photo.patio' },
  13: { id: 13, name: 'Ritning/Planlösning', slug: 'photo.blueprint' }
}

export default {
  components: { Preloader, },
  props: {
    mine: {
      type: Boolean,
      default: false
    },
    featured: {
      type: Boolean,
      default: false
    },
    photos: {
      type: Array,
      required: true
    },
    flatid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      photoRoom: photoRoom,
      showImages: false,
      index: null,
      images: this.photos.map(photo => {
        return {
          ...photo,
          href: this.url(photo).full,
          thumbnail: this.url(photo).full,
          description: photo.description,
          show: false
        }
      })
    }
  },
  computed: {
    ...mapGetters(['user']),

    // photoRoom() {
    //   return _.orderBy(photoRoom, [c => c.name], ['asc'])
    // },
    imageList() {
      return this.images.map(image => image.href)
    }
    // images() {
    //   const _this = this
    //   let images = this.photos.map(photo => {
    //     return {
    //       ...photo,
    //       href: _this.url(photo).full,
    //       thumbnail: _this.url(photo).full,
    //       description: photo.description,
    //       show: false
    //     }
    //   })
    //   return images
    // }
  },
  watch: {
    index: function(newVal, oldVal) {
      // console.log(newVal, oldVal)

      // this.answer = 'Waiting for you to stop typing...'
      if (newVal != null) {
        this.$store.commit('LISTEN_TO_KEYBOARD', false)

        // this.$store.commit('LISTEN_TO_KEYBOARD', false)
      } else {
        this.$store.commit('LISTEN_TO_KEYBOARD', true)
      }
      // this.debouncedGetAnswer()
    }
  },
  methods: {
    imageError(event) {

      Sentry.captureException(event);
      // console.log('error')
    },
    whatRoom(id) {
      return this.photoRoom[id].name // id //this.photoRoom.find(room => room.id === id)
      // return
    },
    loadedOne(el) {
      this.images[el.index].show = true
      // console.log(el)
      // console.log(el.progress)

      // console.log('HEJ')
      // console.log(this.images[el.index])
    },
    loadedAll(e) {
      // console.log(e)
      this.showImages = true
    },
    url(photo) {
      let provider = photo.provider
      let base_url = ''
      let full_url = ''
      if (provider == 's3') {
        full_url =
          'https://s3.eu-west-1.amazonaws.com/bytbostad.info/large/' + photo.img_url
        base_url =
          'https://s3.eu-west-1.amazonaws.com/bytbostad.info/square/' + photo.img_url
      } else if (provider == 'local') {
        base_url = 'https://bytbostad.se/slir/w250-c1x1/upload/' + photo.img_url
        full_url = 'https://bytbostad.se/slir/w1200/upload/' + photo.img_url
      }
      return { square: base_url, full: full_url }
    }
  }
}
</script>

<style scoped>
.image {
  cursor: pointer;
}
</style>
